<template>
	<div class="footer" :class="classListFooter">
		<div class="footer__left">
			<div class="footer__title">Поделиться проектом:</div>
			<div class="footer__share">
				<div class="footer__share-item footer__share-item--fb" data-url="https://austria2022.marieclaire.ru/" data-title="Австрия. Секретные локации в горах и не только" @click="share('fb')" data-type="fb" ref="fb"></div>
				<div class="footer__share-item footer__share-item--vk" data-url="https://austria2022.marieclaire.ru/" data-title="Австрия. Секретные локации в горах и не только" @click="share('vk')" data-type="vk" ref="vk"></div>
				<div class="footer__share-item footer__share-item--ok" data-url="https://austria2022.marieclaire.ru/" data-title="Австрия. Секретные локации в горах и не только" @click="share('ok')" data-type="ok" ref="ok"></div>
				<div class="footer__share-item footer__share-item--tw" data-url="https://austria2022.marieclaire.ru/" data-title="Австрия. Секретные локации в горах и не только" @click="share('twitter')" data-type="twitter" ref="twitter"></div>
				<div class="footer__share-item footer__share-item--tg" data-url="https://austria2022.marieclaire.ru/" data-title="Австрия. Секретные локации в горах и не только" @click="share('telegram')" data-type="telegram" ref="telegram"></div>
			</div>
		</div>
		<div class="footer__copy" v-if="classListFooter !== 'footer--burger'">© 2022,  HSM Creative Studio</div>
	</div>
</template>

<script>
	import JSShare from "js-share";
	export default {
		name: 'FooterBlock',
		data() {
			return {
				showTeam: false
			}
		},
		methods: {
			share(soc) {
				JSShare.go(this.$refs[soc]);
				this.$gtag.event('click', {event_category: 'link','event_label': 'share_'+soc})
			}
		},
		props: {
			classListFooter: String
		}
	}
</script>

<style lang="sass">
	.footer
		display: block
		width: vwm(250px)
		margin: auto
		@media screen and (min-width: 1000px)
			display: flex
			width: 100%
			justify-content: space-between
			align-items: center
			padding-bottom: vw(30px)
			padding-top: vw(14px)
		&__copy
			font-size: vwm(14px)
			text-align: center
			letter-spacing: vwm(0.6px)
			padding-left: vwm(23px)
			padding-bottom: vwm(20px)
			padding-top: vwm(42px)
			@media screen and (min-width: 1000px)
				font-size: vw(14px)
				letter-spacing: vw(0.6px)
				padding-left: vw(0px)
				padding-top: vw(0px)
				padding-bottom: 0
				align-self: flex-end
		&__title
			font-size: vwm(17px)
			text-align: center
			padding-top: vwm(48px)
			letter-spacing: vwm(0.4px)
			padding-left: vwm(30px)
			@media screen and (min-width: 1000px)
				font-size: vw(16px)
				padding-top: vw(0px)
				letter-spacing: vw(0px)
				padding-left: vw(12px)
		&__left
			@media screen and (min-width: 1000px)
				width: vw(250px)
				text-align: center
		&__share
			display: flex
			align-items: center
			justify-content: space-between
			margin-top: vwm(17px)
			padding-left: vwm(0px)
			position: relative
			left: vwm(7px)
			@media screen and (min-width: 1000px)
				margin-top: vw(17px)
				padding-left: vw(0px)
				position: relative
				left: vw(7px)
			&-item
				width: vwm(38px)
				height: vwm(38px)
				background-repeat: no-repeat
				background-size: contain
				transition: all 300ms
				@media screen and (min-width: 1000px)
					width: vw(38px)
					height: vw(38px)
					cursor: pointer
				&--fb
					background-image: url(../assets/img/soc/fb.svg)
					&:hover
						background-image: url(../assets/img/soc/h/fb.svg)
				&--vk
					background-image: url(../assets/img/soc/vk.svg)
					&:hover
						background-image: url(../assets/img/soc/h/vk.svg)
				&--ok
					background-image: url(../assets/img/soc/ok.svg)
					&:hover
						background-image: url(../assets/img/soc/h/ok.svg)
				&--tw
					background-image: url(../assets/img/soc/tw.svg)
					&:hover
						background-image: url(../assets/img/soc/h/tw.svg)
				&--tg
					background-image: url(../assets/img/soc/tg.svg)
					&:hover
						background-image: url(../assets/img/soc/h/tg.svg)
</style>