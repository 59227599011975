<template>
	<div class="main">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container">
			<HeaderBlock />
			<div class="scrollLine" :class="{'active': city1, active2: activeLine2, active3: activeLine3}">
				<div class="main__line-m"></div>
			</div>
			<div class="scrollLine2" :class="{'active': activeLine4}">
				<div class="main__line-m-2"></div>
			</div>
			<div class="main__line-wrapper">
				<div class="main__line" :class="{'active': line}">
					<svg width="1443" height="824" viewBox="0 0 1443 824" fill="none" xmlns="http://www.w3.org/2000/svg" id="line">
						<path d="M1.95447 250.134C18.3998 252.646 33.9206 258.98 48.9332 265.971C64.4725 273.207 75.7223 278.969 87.5276 291.323C108.057 312.808 126.753 335.522 143.29 360.327C158.236 382.747 166.952 408.866 179.223 432.725C181.538 437.227 184.727 441.164 187.141 445.634C190.303 451.49 196.232 457.646 198.121 464C200.222 471.068 201.928 477.533 205.64 484.029C212.079 495.298 213.119 509.699 218.349 521.625C224.965 536.71 231.808 551.671 238.179 566.874C247.433 588.957 257.62 610.408 267.191 632.285C284.377 671.567 311.021 705.48 337.593 739.085C360.708 768.319 387.183 788.171 422.301 800.836C460.144 814.484 499.647 822.662 540.014 822.662C562.186 822.662 584.723 823.774 606.822 821.864C665.5 816.791 721.875 797.818 778.368 782.204C807.573 774.133 835.524 762.915 864.407 753.991C876.013 750.404 887.735 747.37 899.208 743.344C911.246 739.12 922.9 733.988 935.407 731.233C960.668 725.669 987.086 721.907 1012.93 720.853C1042.28 719.655 1071.62 719.442 1100.96 718.324C1113.17 717.859 1125.16 715.312 1137.36 714.93C1156.86 714.321 1175.76 713.411 1195.19 711.137C1208.93 709.53 1222.15 708.202 1235.78 705.681C1262.08 700.816 1290.04 697.212 1313.5 683.456C1330.72 673.355 1348.94 661.505 1363.14 647.39C1377.15 633.456 1387.43 611.816 1395.35 594.09" stroke="#C20E1A" stroke-width="2" stroke-linecap="round" stroke-dasharray="15 15"/>
					</svg>
				</div>
				<div class="main__line-2" :class="{'active': line2}">
					<svg width="110" height="596" viewBox="0 0 110 596" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M62.35 594.09C81.01 552.274 85.82 504.665 71.46 460.806C52.36 402.446 25.12 347.286 10.71 287.53C-1.49999 236.868 -3.61996 185.548 12.11 135.415C28.6 82.8306 58.8001 28.6972 108.66 1" stroke="#C20E1A" stroke-width="2" stroke-linecap="round" stroke-dasharray="15 15"/>
					</svg>
				</div>
			</div>
			<div class="main__content main__content--desk">
				<div class="main__title" data-aos="fade-up">австрия</div>
				<div class="main__text" data-aos="fade-up" data-aos-delay="400">Страна, которую не разгадать при беглом знакомстве. Мы предлагаем заглянуть в шкатулку гор и озёр, чтобы узнать об удивительных местах и услышать захватывающие истории. Ваш взгляд вряд ли зацепится за них на карте, но настоящие сокровища никогда не лежат на виду!</div>
				<div class="main__button" data-aos="fade-up" data-aos-delay="500"><router-link :to="link" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'main_page_button'})">Начать</router-link></div>
				<transition name="slide-fade"><div class="main__label main__label--1" v-if="city1">вкус</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--2" v-if="city2">движение</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--3" v-if="city3">вдохновение</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--4" v-if="city4">дух</div></transition>
				<transition name="slide-fade"><router-link to="/taste" class="main__collage main__collage--1" v-if="city1" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_taste'})"><div class="main__collage-round"></div></router-link></transition>
				<transition name="slide-fade"><router-link @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_motion'})" to="/motion" class="main__collage main__collage--2" v-if="city2"><div class="main__collage-round"></div></router-link></transition>
				<transition name="slide-fade"><router-link to="/inspiration" class="main__collage main__collage--3" v-if="city3" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_inspiration'})"><div class="main__collage-round"></div></router-link></transition>
				<transition name="slide-fade"><router-link to="/spirit" class="main__collage main__collage--4" v-if="city4" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_spirit'})"><div class="main__collage-round"></div></router-link></transition>
			</div>
			<div class="main__content main__content--mob">
				<div class="main__title" data-aos="fade-up">австрия</div>
				<div class="main__text" data-aos="fade-up" data-aos-delay="400">Страна, которую не разгадать при беглом знакомстве. Мы предлагаем заглянуть в шкатулку гор и озёр, чтобы узнать об удивительных местах и услышать захватывающие истории. Ваш взгляд вряд ли зацепится за них на карте, но настоящие сокровища никогда не лежат на виду!</div>
				<div class="main__button" data-aos="fade-up" data-aos-delay="500"><router-link :to="link" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'main_page_button'})">Начать</router-link></div>
				<transition name="slide-fade"><div class="main__label main__label--1" data-aos="fade-up" data-aos-delay="1400">вкус</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--2" data-aos="fade-up" data-aos-delay="900">движение</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--3" data-aos="fade-up" data-aos-delay="900">вдохновение</div></transition>
				<transition name="slide-fade"><div class="main__label main__label--4" data-aos="fade-up" data-aos-delay="900">дух</div></transition>
				<router-link to="/taste" class="main__collage main__collage--1" data-aos="fade-up" data-aos-delay="1400" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_taste'})"><div class="main__collage-round"></div></router-link>
				<router-link to="/motion" class="main__collage main__collage--2"  data-aos="fade-up" data-aos-delay="600" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_motion'})"><div class="main__collage-round"></div></router-link>
				<router-link to="/inspiration" class="main__collage main__collage--3"  data-aos="fade-up" data-aos-delay="600" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_inspiration'})"><div class="main__collage-round"></div></router-link>
				<router-link to="/spirit" class="main__collage main__collage--4" data-aos="fade-up" data-aos-delay="600" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_spirit'})"><div class="main__collage-round"></div></router-link>
			</div>
			<FooterBlock classListFooter="footer--main"></FooterBlock>
		</div>
	</div>
</template>

<script>
	import AOS from 'aos'
	import Burger from '@/components/Burger'
	import FooterBlock from '@/components/FooterBlock'
	import HeaderBlock from '@/components/HeaderBlock'
	import 'aos/dist/aos.css'
	export default {
		name: 'Main',
		components: {
			Burger,
			FooterBlock,
			HeaderBlock,
		},
		data() {
			return {
				line: false,
				line2: false,
				city1: false,
				city2: false,
				city3: false,
				city4: false,
				showBurger: false,
				showPanel: false,
				scrollLine: 0,
				activeLine2: false,
				activeLine3: false,
				activeLine4: false,
				link: null,
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				this.scrollLine = procentScroll*20;
				this.scrollLine2 = procentScroll*12;
				if (procentScroll > 5) {
					this.activeLine2 = true;
				} else {
					this.activeLine2 = false;
				}
				if (procentScroll > 20) {
					this.activeLine3 = true;
				} else {
					this.activeLine3 = false;
				}
				if (procentScroll > 50) {
					this.activeLine4 = true;
				} else {
					this.activeLine4 = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
		},
		mounted() {
			this.$gtag.pageview({page_path: '/'})
			window.addEventListener('scroll', this.handleScroll);
			let links = ['/taste', '/motion', '/spirit', '/inspiration', '/raevsky', '/yakovina', '/telnov', '/rastegaeva'];
			this.link = links[Math.floor(Math.random() * links.length)];
			AOS.init({
				duration: 600
			});
			setTimeout(() => {
				this.line = true;
			}, 500)
			setTimeout(() => {
				this.city1 = true;
			}, 800)
			setTimeout(() => {
				this.city2 = true;
			}, 1200)
			setTimeout(() => {
				this.city3 = true;
			}, 3100)
			setTimeout(() => {
				this.line2 = true;
			}, 3950)
			setTimeout(() => {
				this.city4 = true;
			}, 4200)
		}
	}
</script>

<style lang="sass">
	.main__label:before
		background-repeat: no-repeat
	@media screen and (min-width: 1000px)
		.main__collage-round
			display: none
		.main__collage:hover .main__collage-round
			display: block
	.scrollLine2
		transition: all 1s
		transition-delay: 100ms
		position: relative
		overflow: hidden
		width: 100vw
		top: vwm(1139px)
		min-height: 1px
		position: absolute
		&.active
			min-height: 330vw
	.scrollLine
		transition: all 1s
		transition-delay: 100ms
		position: relative
		overflow: hidden
		width: 100vw
		position: absolute
		min-height: 1vw
		&.active
			min-height: 130vw
		&.active2
			min-height: 243vw
		&.active3
			min-height: 343vw
	.footer--main
		display: none
	.main
		height: 54vw
		.container
			position: relative
		&__button
			position: relative
			width: vw(200px)
			height: vw(45px)
			line-height: vw(45px)
			margin: auto
			margin-top: vw(36px)
			text-transform: uppercase
			text-align: center
			font-size: vw(17px)
			a
				background-color: #C20E1A
				border-radius: vw(50px)
				color: #fff
				text-decoration: none
				display: block
				z-index: 10
				&:after
					content: ''
					width: 100%
					height: 100%
					border-radius: vw(50px)
					background-color: #fff
					display: block
					position: absolute
					z-index: -1
					top: 0
					left: 0
					transition: all 300ms
				&:hover
					&:after
						left: vw(-5px)
						top: vw(5px)
		&__text
			width: vw(502px)
			text-align: center
			margin: auto
			margin-top: vw(30px)
			color: #000
			font-size: vw(17px)
			line-height: vw(21px)
		&__title
			text-align: center
			font-size: vw(100px)
			color: #C20E1A
			font-family: 'PosteRetro'
			margin-top: vw(170px)
			padding-left: vw(10px)
			letter-spacing: 0.07em
		&__collage
			position: absolute
			transition: all 300ms
			z-index: 200
			&:hover
				transform: scale(1.06)
				cursor: pointer
			&-round
				width: vw(113px)
				height: vw(113px)
				background-size: contain
				position: relative
				&:after
					content: ''
					display: block
					width: 100%
					height: 100%
					background-size: vw(13px)
					background-position: 50% 50%
					background-repeat: no-repeat
					background-image: url(../assets/img/down.svg)
					position: absolute
					top: 0
					left: 0
				&:before
					content: ''
					display: block
					width: 100%
					height: 100%
					background-image: url(../assets/img/round-text.svg)
					background-size: contain
					background-repeat: no-repeat
					position: absolute
					top: 0
					left: 0
					animation: rotateRound
					animation-timing-function: linear
					animation-duration: 8s
					animation-iteration-count: infinite
			&--1
				width: vw(402px)
				height: vw(210px)
				background-image: url(../assets/img/main-1.png)
				background-size: contain
				top: vw(209px)
				left: vw(-74px)
				.main__collage-round
					left: vw(177px)
					top: vw(-50px)
			&--2
				width: vw(400px)
				height: vw(207px)
				background-image: url(../assets/img/main-2.png)
				background-size: contain
				top: vw(517px)
				left: vw(10px)
				.main__collage-round
					left: vw(317px)
					top: vw(-43px)
			&--3
				width: vw(455px)
				height: vw(238px)
				background-image: url(../assets/img/main-3.png)
				background-size: contain
				top: vw(495px)
				left: vw(751px)
				.main__collage-round
					left: vw(135px)
					top: vw(-33px)
			&--4
				width: vw(385px)
				height: vw(222px)
				background-image: url(../assets/img/main-4.png)
				background-size: contain
				top: vw(153px)
				left: vw(927px)
				.main__collage-round
					left: vw(55px)
					top: vw(-33px)
		&__label
			display: flex
			align-items: center
			font-family: 'Gogol'
			color: #C20E1A
			font-size: vw(30px)
			&--1
				position: absolute
				top: vw(148px)
				left: vw(-80px)
			&--2
				position: absolute
				top: vw(504px)
				left: vw(116px)
			&--3
				position: absolute
				top: vw(456px)
				left: vw(1078px)
				flex-direction: row-reverse
				&:before
					margin-right: 0
					margin-left: vw(20px)
			&--4
				position: absolute
				top: vw(99px)
				left: 78.03056vw
				flex-direction: row-reverse
				&:before
					margin-right: 0
					margin-left: vw(20px)
			&:before
				content: ''
				display: block
				width: vw(12px)
				height: vw(12px)
				margin-right: vw(19px)
				background-size: contain
				background-image: url(../assets/img/dot.svg)
		&__line
			width: 0
			height: vw(828px)
			margin-left: vw(-148px)
			margin-top: vw(-182px)
			position: absolute
			overflow: hidden
			transition: width 4s
			svg
				width: vw(1446px)
				height: auto !important
			&.active
				width: vw(1439px)
		&__line-2
			width: vw(107.73px)
			height: 0
			right: vw(-64px)
			bottom: 4vw
			position: absolute
			overflow: hidden
			transition: height 2s
			svg
				width: 100% !important
				position: absolute
				bottom: 0
				height: auto !important
			&.active
				height: vw(593.09px)
		&__header
			padding-top: vw(40px)
			display: flex
			align-items: center
			position: relative
			z-index: 100
			&-label
				display: none
			&-x
				display: none
			&-mc
				width: vw(118px)
				height: vw(20px)
				background-image: url(../assets/img/logo-mc.svg)
				background-size: contain
				background-repeat: no-repeat
				position: relative
				left: vw(41px)
				top: vw(-3px)
				a
					display: block
					width: 100%
					height: 100%
			&-menu
				display: flex
				align-items: center
				margin-left: auto
				margin-right: auto
				&-item
					margin-right: vw(48.2px)
					&:last-child
						margin-right: 0
					a
						font-size: vw(18px)
						color: #000
						text-decoration: none
						position: relative
						display: block
						letter-spacing: vw(1px)
						&:hover
							&:after
								width: 100%
						&:after
							content: ''
							display: block
							width: 0
							height: vw(13px)
							position: absolute
							transition: all 300ms
							bottom: vw(-20px)
							background-image: url(../assets/img/menu-line.svg)
							background-size: contain
							background-repeat: no-repeat
			&-logo
				display: block
				width: vw(108px)
				height: vw(20px)
				background-image: url(../assets/img/logo.png)
				background-size: contain
				margin-left: vw(-11px)
				a
					display: block
					width: 100%
					height: 100%
	.main__content--mob
		display: none
	.main__line-m
		display: none
	@media screen and (max-width: 1000px)
		.main__button
			width: 60vw
			height: 9vw
			line-height: 9vw
			font-size: 5vw
			border-radius: 8vw
			background: none
			a
				border-radius: 8vw
				&:after
					border-radius: 8vw

		.main__content--desk
			display: none
		.main__content--mob
			display: block
		.footer--main
			display: block
		.main
			height: auto !important
		.main__line
			display: none
		.main__line-m
			display: block
			position: absolute
			width: vwm(457px) !important
			height: vwm(785px) !important
			background-image: url(../assets/img/line-m.svg)
			background-size: contain
			top: vwm(272px)
			left: vwm(-57px)
			margin-left: 0
			margin-top: 0
			background-size: vwm(457px) !important
		.main__line-m-2
			display: block
			position: absolute
			width: vwm(536px) !important
			height: vwm(515px) !important
			background-image: url(../assets/img/line-m-2.svg)
			background-size: contain
			top: 0
			left: vwm(-68px)
			margin-left: 0
			margin-top: 0
			background-size: vwm(536px) !important
		.main__title
			font-size: vwm(66px)
			margin-top: vwm(124px)
		.main__content
		.main__text
			font-size: 4.4vw
			width: 92.26667vw
			line-height: 5.56667vw
			margin-top: 5.33333vw
			span
				display: block
			br
				display: none
		.main__label
			font-size: vwm(30px)
			&:before
				width: vwm(12px)
				height: vwm(12px)
			&--1
				left: 5.44444vw
				top: 114.3vw
				&:before
					position: relative
					left: 23vw
			&--2
				left: 48.24444vw
				top: 193vw
				&:before
					position: relative
					left: -3.7vw
			&--3
				left: 16vw
				top: 270.8vw
				&:before
					position: relative
					left: -47.7vw
			&--4
				left: 36vw
				top: 364.8vw
				&:before
					position: relative
					left: -23.7vw
		.main__content
			min-height: vwm(1458px)
		.main__collage
			display: block
			position: relative
			top: auto
			left: auto
			right: auto
			bottom: auto
			&-round
				width: vwm(110px)
				height: vwm(110px)
				&:after
					background-size: vwm(13px)
			&--1
				width: vwm(370px)
				background-repeat: no-repeat
				height: vwm(209px)
				margin-top: vwm(66px)
				margin-left: vwm(-2px)
				.main__collage-round
					left: vwm(207px)
					top: vwm(-36px)
			&--2
				width: vwm(368px)
				background-repeat: no-repeat
				height: vwm(209px)
				margin-top: vwm(90px)
				margin-left: vwm(3px)
				.main__collage-round
					left: vwm(45px)
					top: vwm(-23px)
			&--3
				width: vwm(368px)
				background-repeat: no-repeat
				height: vwm(209px)
				margin-top: vwm(130px)
				margin-left: vwm(3px)
				.main__collage-round
					left: vwm(183px)
					top: vwm(-28px)
			&--4
				width: vwm(368px)
				background-repeat: no-repeat
				height: vwm(209px)
				margin-top: vwm(75px)
				margin-left: vwm(3px)
				.main__collage-round
					left: vwm(249px)
					top: vwm(-43px)
		.main__header
			&-panel
				width: 100%
				height: vwm(62px)
				background-color: #D9CFC1
				position: fixed
				z-index: 3090900
				top: 0
				.main__header-mc
					left: vwm(15px)
					top: vwm(19px)
			&-burger
				width: vwm(35px)
				height: vwm(23.3px)
				display: flex
				flex-direction: column
				position: absolute
				right: vwm(15px)
				top: vwm(19px)
				justify-content: space-between
				span
					display: block
					width: 100%
					height: 1px
					background-color: #C20E1A
			&-mc
				position: absolute
				width: vwm(119px)
				height: vwm(20px)
				left: vwm(46px)
				top: vwm(50px)
				background-repeat: no-repeat
			&-logo
				position: absolute
				width: vwm(126px)
				height: vwm(23px)
				right: vwm(49px)
				top: vwm(50px)
				background-repeat: no-repeat
			&-x
				display: block
				position: absolute
				top: vwm(49px)
				transform: scale(1, 1.5)
				left: vwm(-10px)
				right: 0
				margin: auto
				font-size: vwm(20px)
				text-align: center
			&-menu
				display: none
			&-label
				display: block
				position: absolute
				left: 0
				right: 0
				width: 100%
				text-align: center
				font-size: vwm(16px)
				text-transform: uppercase
				letter-spacing: vwm(1.3px)
				padding-top: vwm(38px)
				display: none
</style>

<style>
.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
	transform: translateY(vw(10px));
	opacity: 0;
}
.slide-fader-enter-active {
	transition: all .1s ease;
}
.slide-fader-leave-active {
	transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fader-enter, .slide-fader-leave-to {
	transform: translateY(vw(-10px));
	opacity: 0;
}
</style>