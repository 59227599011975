<template>
	<div class="page page--raevsky" :class="{white: white, blue: blue}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="0" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block main__block--raevsky">
			<div class="page__header">
				<HeaderBlock />
			</div>
			<Intro class="intro--5">
				<template v-slot:title>
					австрия глазами <br>владимира раевского
				</template>
				<template v-slot:text>
					Теле- и радиоведущий, автор программ об истории и культуре <br>на радио “Серебряный дождь”
				</template>
				<template v-slot:collage>
					<div class="collage collage--5">
						<img src="../assets/img/collage-5.png" alt="">
						<div class="collage__5-el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-6" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-7" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-8" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-9" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
						<div class="collage__5-el-10" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--2 collage--mob"></div>-->
					<div class="intro__round intro__round--5"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--r-1">
				<template v-slot:title>
					замок амбрас
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/JCy2XrZAt3DBJwXP9" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_26'})">Schloßstraße 20, 6020 Innsbruck, Австрия</a>
				</template>
				<template v-slot:text>
					Один из самых эксцентричных европейских монархов, император Священной Римской империи Фердинанд II, построил в Инсбруке замок, где собрал все, что его интересовало, а прежде всего — странные вещи. Поэтому самый необычный раздел музея — палата диковинок и редкостей. Портреты полностью покрытых волосами людей, изображение человека, выжившего с османским копьем в глазу, книга рекордов пьянства, туфли венецианских куртизанок, портрет графа Дракулы и скелет рыбы-ежа. И все — с захватывающим видом на Альпы, который и сам с закатом становится диковинней и кунсткамерней, чем любой из фердинандовых экспонатов.
				</template>
				<template v-slot:large>
					самый необычный раздел музея — <br>палата диковинок и редкостей <br>Фердинанда II.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--r1"><img src="../assets/img/r-1.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__photo-r1" data-aos="zoom-in"></div><div class="content__round content__round--r5 content__round--r1"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-beige">
			<Content class="content content--r-2" :smalltitle="false" :largetitle="true" infoclass="content__info--r2" largeclass="content__large--t2" :reverse="true">
				<template v-slot:title>
					кофе
				</template>
				<template v-slot:location>
					<a href="https://g.page/220grad-chiemseegasse?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_27'})">220Grad Chiemseegasse 5</a>
				</template>
				<template v-slot:text>
					Кофейни «третьей волны» — те, в которых работают задумчивые молодые люди и где за кофейным зерном можно проследить от плантации в Колумбии до чашки. Такие кофейни обычно отделаны в духе минимализма — светлые стены, деревянная стойка и меню большими буквами прямо над ней. Так вот, эти кофейни, самые прогрессивные заведения в любом городе мира, в Австрии недолюбливают. Как же! Ведь кофейня — это сумрачное место с газетами на палке, белыми скатертями и высокомерными официантами. Это ожидаемо — новые кофейни трудно принимают в любой стране с собственной кофейной культурой: например, в Италии или Соединенных Штатах. Но натиск третьей волны не остановить, и вот ее гребни в, казалось бы, консервативном Зальцбурге.
				</template>
				<template v-slot:large>
					Такие кофейни обычно отделаны <br>в духе минимализма — светлые стены, <br>деревянная стойка и меню большими <br>буквами прямо над ней.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--r3"><img src="../assets/img/r-3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--r5 content__round--r2"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content class="content content--r-3" :smalltitle="true" infoclass="content__info--t4" largeclass="content__large--4" :reverse="false">
				<template v-slot:title>
					миры <br>сваровски
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/2fR8ZmEz73S26cdJ8" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_28'})">Kristallweltenstraße 1, 6112 Wattens, Австрия</a>
				</template>
				<template v-slot:text>
					Недалеко от Инсбрука, в маленьком городке Ваттенсе, находится вторая по популярности достопримечательность Австрии — Музей кристальных миров Swarovski (первая — императорский дворец Шенбрунн). Музей Swarovski построили прямо на фоне огромного производства компании, и это, прямо скажем, довольно контрастное сооружение. Вход в него — голова великана с извергающимся изо рта водопадом. Внутри — созданные специально для музея и, разумеется, из кристаллов инсталляции современных художников. Здесь есть и сценография Ники де Сен-Фалль, и музыкальные эксперименты Брайана Ино, и невероятно обаятельные пляшущие китчевые сердечки индийского дизайнера Маниша Ароры, но что радостнее всего — наша группа «Синие носы» (Вячеслав Мизин и Александр Шабуров) с просыпающимся в хрустальном гробу Лениным.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--r4"><img src="../assets/img/r-4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__photo-r2"></div><div class="content__round content__round--r5 content__round--r3"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content class="content content--r-4" :smalltitle="true" infoclass="content__info--t5" largeclass="content__large--t5" :reverse="true">
				<template v-slot:title>
					рынок <br>в зальцбурге
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/AjATaDRsBA1mQKYL9" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_29'})">5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text>
					Фермерский рынок в Зальцбурге — такая же часть городского пейзажа, как магазины с рождественскими украшениями, конфеты «моцарткугель» и сувенирные лавки. Грюнмаркт, то есть зеленый рынок, умещается на небольшой площади у ступеней университетской церкви Коллегиенкирхе. Вокруг постоянно бродит много туристов, но это совершенно не делает рынок туристическим — сюда приходят за колбасами, сырами, овощами и фруктами и обычные горожане. Даже яблоки со светлыми пигментными сердечками (на зеленое яблоко налепляют наклейку и отклеивают, когда все остальное покраснело) пользуются одинаковым спросом у туристических групп и чопорных старушек. Но лучшее время на рынке — воскресенье, когда зальцбуржцы в любую погоду выпивают на рыночной площади шпритцер с сосисками. Впрочем, можно и не шпритцер, а вот без сосисок — никак нельзя.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--r5"><img src="../assets/img/r-6.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--r5 content__round--r4"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--t6" largeclass="content__large--t6" :reverse="false" class="content--r-7">
				<template v-slot:title>
					фюген и музей
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/vbaUEQEYVcYvLqb7A" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_30'})">Lindenweg 2, 6263 Fügen, Австрия</a>
				</template>
				<template v-slot:text>
					В крохотном тирольском городке Фюген есть краеведческий музей, в который туристов из Западного мира обычно зазывают историей песни «Тихая ночь, святая ночь». Но даже если в вашей душе эта песня не отзывается сладкой праздничной дрожью, все равно зайдите туда (Lindenweg road 2 - 6263 - Fügen). Ведь кроме милых рождественских песенок там можно увидеть, например, искусно расписанные мишени для соревнований тирольских стрелков (причем мишени легко могут нарисовать местную церковь и гордо целиться в солнышко над ней). А есть колоссальный барочный комод, увезенный во время Второй мировой в Америку и выкупленный жителями Фюгена в складчину, — на нем нарисованы звери подиковинней сказочных. Впрочем, горы вокруг такой красоты, что трехглавых зверей, вполне верю, писали с натуры.
				</template>
				<template v-slot:large>
					кроме милых рождественских песенок <br>там можно увидеть, например, искусно <br>расписанные мишени для соревнований тирольских стрелков.</template>
				<template v-slot:image>
					<div class="content__image content__image--r6"><img src="../assets/img/t-8.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-r3" data-aos="fade-left" data-aos-delay="400"></div><div class="content__round content__round--r5 content__round--r5"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-green">
			<Content infoclass="content__info--t7" largeclass="content__large--t7" class="content--r-5" :reverse="true">
				<template v-slot:title>
					искусство <br>в зальцбурге
				</template>
				<template v-slot:location>
					<a href="https://g.page/museumdermodernesalzburg?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_31'})">Wiener-Philharmoniker-Gasse 9, 5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text>
					В Зальцбурге уйма художественных галерей — от крупных и богатых до совсем крохотных. А над всем этим царит Музей современного искусства. «Над» — потому что находится он на горе. Забраться на гору Менхсберг можно на лифте (4 евро туда — обратно) или пешком (минут 25 вверх, 10 — вниз). <div class="serpator"></div> Музей построили в 2004 году, и он скромно соседствует с пышной водонапорной башней 1892 года (башня, похожая на крепость, тоже смотрится как арт-объект и будто передразнивает настоящий средневековый замок, нависающий над Зальцбургом с другой стороны).
				</template>
				<template v-slot:image>
					<div class="content__image content__image--r7"><img src="../assets/img/r-9.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-r4" data-aos="fade-right" data-aos-delay="400"></div></div>
				</template>
			</Content>
			<Content :smalltitle="true" infoclass="content__info--t7" largeclass="content__large--t7" class="content--r-6">
				<template v-slot:text>
					Итак, в музее вас ждет три этажа первоклассных, тщательно сделанных выставок мировых авторов. Например, выставка художницы Эллен Харви из Нью-Йорка под названием «Разочарованный турист» (конечно, этим названием она издевается и над Зальцбургом в том числе — уж Зальцбург-то никого не оставляет разочарованным). <div class="serpator"></div> С 2019 года Харви просит людей со всего мира присылать ей места, где хотелось бы, но невозможно оказаться. Результат — колоссальная серия масляных картин, изображающая навсегда исчезнувшие, но оставшиеся в памяти и воображении места.
				</template>
				<template v-slot:large>
					в музее вас ждет три этажа <br>первоклассных, тщательно сделанных выставок мировых авторов. </template>
				<template v-slot:image>
					<div class="content__image content__image--r8"><img src="../assets/img/r-11.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--r5 content__round--r6"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax'
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				blue: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Content,
			Burger,
			FooterBlock,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/raevsky'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				console.log(procentScroll)
				if (window.innerWidth > 1000) {
					if (procentScroll < 10) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 10) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 28) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 38) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 45) {
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 52) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 64) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 78) {
						this.white = false;
						this.blue = false;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 85) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 82) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_100'})
							this.scroll100 = true;
						}
					}
				} else {
					if (procentScroll > 3) {
						this.white = true;
						this.blue = false;
					} else {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 6) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 18) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 32) {
						this.white = true;
						this.blue = false;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 46) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 58) {
						this.white = true;
						this.blue = false;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 80) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_raevsky_100'})
							this.scroll100 = true;
						}
					}
					if (procentScroll > 75) {
						this.white = false;
						this.blue = false;
					}
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
		}
	}
</script>

<style lang="sass">
	.main__block--raevsky
		@media screen and (max-width: 1000px)
			min-height: vwm(666px) !important
	.content__image--r4
		width: vw(517px)
		height: vw(624px)
		z-index: 9
		position: relative
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content__image--r5
		width: vw(490px)
		height: vw(587px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content__image--r6
		width: vw(517px)
		height: vw(624px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content__image--r7
		width: vw(490px)
		height: vw(560px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content__image--r8
		width: vw(576px)
		height: vw(415px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content--r-3
		padding-top: vw(198px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(10px)
	.content--r-4
		padding-top: vw(253px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(30px)
		.content__title--small
			margin-top: 0
	.content--r-5
		padding-top: vw(218px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(20px)
	.content--r-6
		padding-top: vw(58px)
		.content__text
			@media screen and (max-width: 1000px)
				margin-top: 0 !important
		@media screen and (max-width: 1000px)
			padding-bottom: vwm(0px)
	.content--r-7
		@media screen and (max-width: 1000px)
			padding-top: vwm(10px) !important
	.content--r-2
		padding-top: vw(305px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(20px)
		.content__title--large
			margin-top: vw(-15px)
	.content__round--r1
		bottom: vw(-110px)
		left: vw(-111px)
	.content__round--r2
		left: vw(369px)
		bottom: vw(-80px)
	.content__round--r3
		bottom: vw(-87px)
		left: vw(-101px)
	.content__round--r4
		bottom: vw(-94px)
		left: vw(367px)
	.content__round--r5
		bottom: vw(-106px)
		left: vw(-86px)
	.content__round--r6
		top: vw(-104px)
		left: vw(-105px)
	.content__image--r1
		width: vw(517px)
		height: vw(624px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content__image--r3
		width: vw(490px)
		height: vw(587px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
	.content--r-1
		padding-top: vw(81px)
		.content__title
			letter-spacing: vw(3.6px)
	.content__photo-r1
		background-image: url(../assets/img/r-2.png)
		width: vw(233px)
		height: vw(259px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		right: vw(-87px)
		bottom: vw(-76px)
		@media screen and (max-width: 1000px)
			width: vwm(104px)
			height: vwm(117px)
			right: vwm(10px)
	.content__photo-r2
		background-image: url(../assets/img/r-5.png)
		width: vw(317px)
		height: vw(255px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		right: vw(-85px)
		bottom: vw(-73px)
		z-index: 100
		@media screen and (max-width: 1000px)
			width: vwm(167px)
			height: vwm(109px)
			right: vwm(-17px)
			bottom: vwm(-23px)
	.content__photo-r3
		background-image: url(../assets/img/r-8.png)
		width: vw(281px)
		height: vw(280px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		right: vw(-85px)
		bottom: vw(-92px)
		@media screen and (max-width: 1000px)
			width: vwm(123px)
			height: vwm(109px)
			right: vwm(-6px)
			bottom: vwm(-19px)
	.content__photo-r4
		background-image: url(../assets/img/r-10.png)
		width: vw(271px)
		height: vw(321px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(-36px)
		bottom: vw(-70px)
		@media screen and (max-width: 1000px)
			width: vwm(200px)
			height: vwm(121px)
			bottom: vwm(-20px)
			left: vwm(-13px)
	.content__round--r5
		background-image: url(../assets/img/round-5.svg)
		width: vw(207px)
		height: vw(207px)
	.intro--5
		margin-top: vw(-16px)
		.intro__title
			font-size: vw(65px)
			line-height: vw(84px)
			letter-spacing: vw(6.4px)
			@media screen and (max-width: 1000px)
				font-size: vwm(34px)
				line-height: vwm(44px)
		.intro__text
			margin-top: vw(11px)
		.intro__round--5
			right: vw(512px)
			top: vw(64px)
			@media screen and (max-width: 1000px)
				top: vwm(-42px)
				right: vwm(149px)
	.intro__collage 
		z-index: 1000
		position: relative
	.bg-white
		//background-color: #fff
	.bg-blue
		//background-color: #D4E5EF
		//background-image: url(../assets/img/noise.png)
	.bg-green
		//background-color: #C9D8B6
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.collage
		&__5
			&-el-1
				position: absolute
				left: vw(-13px)
				top: vw(36px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(18px)
					top: vwm(-12px)
				span
					width: vw(151px)
					height: vw(238px)
					background-image: url(../assets/img/collage-5-el-1.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDownAlt
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(177px)
						height: vwm(109px)
			&-el-2
				position: absolute
				left: vw(-43px)
				top: vw(133px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(9px)
					left: vwm(-12px)
				span
					width: vw(326px)
					height: vw(355px)
					background-image: url(../assets/img/collage-5-el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 9s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(233px)
						height: vwm(158px)
			&-el-3
				position: absolute
				left: vw(-24px)
				bottom: vw(133px)
				z-index: 5
				@media screen and (max-width: 1000px)
					left: vwm(10px)
					bottom: vwm(41px)
				span
					width: vw(197px)
					height: vw(135px)
					background-image: url(../assets/img/collage-5-el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(93px)
						height: vwm(58px)
			&-el-4
				position: absolute
				right: vw(-50px)
				top: vw(-19px)
				z-index: 8
				@media screen and (max-width: 1000px)
					left: vwm(296px)
					top: vwm(-11px)
				span
					width: vw(284px)
					height: vw(308px)
					background-image: url(../assets/img/collage-5-el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(103px)
						height: vwm(97px)
			&-el-5
				position: absolute
				right: vw(191px)
				top: vw(109px)
				z-index: 6
				@media screen and (max-width: 1000px)
					top: vwm(48px)
					left: vwm(214px)
				span
					width: vw(184px)
					height: vw(182px)
					background-image: url(../assets/img/collage-5-el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(61px)
						height: vwm(108px)
			&-el-6
				position: absolute
				right: vw(91px)
				top: vw(-11px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(1px)
					right: vwm(7px)
				span
					width: vw(131px)
					height: vw(342px)
					background-image: url(../assets/img/collage-5-el-6.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(109px)
						height: vwm(117px)
			&-el-7
				position: absolute
				right: vw(-22px)
				bottom: vw(-30px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(141px)
					right: vwm(21px)
				span
					width: vw(226px)
					height: vw(272px)
					background-image: url(../assets/img/collage-5-el-7.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(75px)
						height: vwm(90px)
			&-el-8
				position: absolute
				right: vw(-43px)
				bottom: vw(201px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(116px)
					right: vwm(5px)
				span
					width: vw(139px)
					height: vw(120px)
					background-image: url(../assets/img/collage-5-el-8.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(49px)
						height: vwm(90px)
			&-el-9
				position: absolute
				right: vw(141px)
				bottom: vw(-9px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(173px)
					right: vwm(78px)
				span
					width: vw(161px)
					height: vw(169px)
					background-image: url(../assets/img/collage-5-el-9.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(64px)
						height: vwm(79px)
			&-el-10
				position: absolute
				right: vw(518px)
				top: vw(87px)
				z-index: 3
				@media screen and (max-width: 1000px)
					top: vwm(-15px)
					right: vwm(126px)
				span
					width: vw(176px)
					height: vw(170px)
					background-image: url(../assets/img/collage-5-el-10.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(87px)
						height: vwm(90px)
		&--5
			margin-top: vw(-48px)
			margin-left: vw(9px)
			width: vw(1250px)
			@media screen and (max-width: 1000px)
				width: vwm(383px) !important
				margin-left: vwm(-6px) !important
				margin-top: vwm(60px) !important
	.page 
		&--raevsky
			background-color: #D9BFA1
			transition: all 300ms
			min-height: 100vh
			&.blue
				background-color: #D4E5EF
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1093px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/bg-5.svg)
				background-repeat: no-repeat
				background-position: 91% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
	.main__block
		min-height: vw(1063px)
	.collage-mob--2
		@media screen and (max-width: 1000px)
			width: vwm(378px)
			height: vwm(237px)
			background-image: url(../assets/img/collage-2-full.png)
			background-size: contain
			margin-top: vwm(107px)
			background-repeat: no-repeat
	.main__block--taste
		@media screen and (max-width: 1000px)
			min-height: 140vw !important
		@media screen and (max-width: 1000px)
			.intro__round--2
				top: -16.90278vw
</style>