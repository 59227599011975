<template>
	<div class="page page--motion" :class="{white: white}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="2" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block main__block--motion">
			<div class="page__header">
				<HeaderBlock />
			</div>
			<Intro class="intro--4">
				<template v-slot:title>
					австрия в движении
				</template>
				<template v-slot:text>
					Взаимодействовать с ландшафтами, играть и двигаться: вверх-вниз, вперёд-назад и даже во времени!
				</template>
				<template v-slot:collage>
					<div class="collage collage--4">
						<img src="../assets/img/collage-4-el-1.png" alt="">
						<div class="collage__4-el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__4-el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__4-el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__4-el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__4-el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__4-el-6" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--2 collage--mob"></div>-->
					<div class="intro__round intro__round--4"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--t-1">
				<template v-slot:title>
					восход<br> на лыжах
				</template>
				<template v-slot:location>
					<a href="https://g.page/myzillertalarena?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_7'})">Rohr 23, 6280 Rohrberg, Австрия</a>
				</template>
				<template v-slot:text>
					На часах семь утра, морозный воздух обжигает, солнце только-только выплывает над снежными пиками, а вы уже на ногах. И не просто<br/>на ногах, а при полной горнолыжной экипировке, хотя такой ранний подъем, возможно, дался нелегко. Но эмоции зашкаливают: подъемник открывают специально для вас! Внизу проплывают еще спящие<br/>в рассветной дымке заснеженные склоны — к моменту подъема<br/>на верхнюю станцию они уже купаются в ярком свете. В ближайшие пару часов эти склоны будут ваши — и только ваши.
					<div class="serpator"></div>
					В Тирольских Альпах утренние катания до открытия подъемников организуют как минимум на десятке курортов. В их числе —Майерхофен, Зерфаус, Арена Циллерталь. Группы от 5 до 50 человек всегда сопровождает инструктор. Трассы для таких любителей тишины<br/>и свежего снега всегда готовят специально. В этих краях есть выражение “The early bird gets the corduroy” — “Тот, кто рано встает, получает “вельветовый” склон” — только что обработанный ратраком спуск.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--h8"><img src="../assets/img/m-1.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-m1" data-aos="zoom-in"></div><div class="content__round content__round--1 content__round--m"></div></div>
				</template>
			</Content>
			<Content :short="true" :reverse="true">
				<template v-slot:text>
					<div class="content__text-item-m1">
						А спустя примерно полтора часа лыжников ждет завтрак в одном из горных ресторанчиков. Например, в Обергургле (долина Оцталь на самой границе с Италией), где утреннее катание в сезон проводят каждую пятницу, голодным лыжникам предлагают сытный завтрак с шампанским в ресторане Хоэ Мут (Hohe Mut Alm) на высоте 2670 метров над уровнем моря. 
					</div>
				</template>
				<template v-slot:large>
					СО СМОТРОВОЙ ПЛОЩАДКИ В ХОРОШУЮ ПОГОДУ ВИДНЫ 21 ГОРНЫЙ ПИК, а альпийское меню зарядит энергией для еще нескольких часов катания.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--m2"><img src="../assets/img/m-2.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue-2">
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t2" largeclass="content__large--t2">
				<template v-slot:title>
					машина<br/> времени
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/FPGYpn9onFBBnKNh8" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_8'})">Vallugagrat, 6580, 6580 St. Anton am Arlberg, Австрия</a>
				</template>
				<template v-slot:text>
					В момент пуска в далеком 1955 году Валлуга II был самым высокогорным подъемником не только в Санкт-Антоне, но и во всей Австрии. C тех пор его ремонтировали только однажды —- в 1979 году: тесные кабинки на 5 пассажиров заменили на более просторные. К старту этого винтажного синего подъемника с нижней станции за несколько минут доставляет ультра-современный Galzigbahn. 
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t2"><img src="../assets/img/m-3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""></div>
				</template>
				<template v-slot:large>
					Затем вы пересаживаетесь в тесную, пропахшую машинным маслом кабинку — и переноситесь на полвека назад, когда подъемниками управляли не компьютеры, а люди.
				</template>
			</Content>
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t3" largeclass="content__large--t3" :reverse="true" class="content--t3">
				<template v-slot:text>
					На пересадке лыжников встречает Эрик Швайгер  по прозвищу “Нагги”. Эрик работает горнолыжным гидом уже более 40 лет и знает в этих краях каждый камень. Старинный подъемник Валлуга он ласково называет своим “офисом”. По его подсчетам, он поднимался на нем более 700 раз! Склоны здесь достаточно крутые — их оценят опытные лыжники. Даже самым искушенным фрирайдерам допуск на Валлугашпице (2811 м) разрешен только в сопровождении гида. 
					<div class="serpator"></div>
					Но подняться на верхнюю точку Санкт-Антона могут и желающие лишь полюбоваться видами со смотровой площадки, а потом согреться сытным мясным супом или горячим шоколадом в ресторанчике Valluga View. Острые ощущения в любом случае гарантированы. Крошечная кабинка раскачивается на ветру и буквально взлетает по практически вертикальному склону. 
				</template>
				<template v-slot:large>
					И очень скоро перед восхищенными пассажирами открывается панорама сразу нескольких горнолыжных регионов, включая швейцарский Граубюнден.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t3 content__image--h2"><img src="../assets/img/m-4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-m2" data-aos="fade-left" data-aos-delay="400"></div><div class="content__round content__round--5 content__round--m"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--t4" largeclass="content__large--4" :reverse="true">
				<template v-slot:title>
					хайкинг<br/>в городе
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/djaZGXNZdtLEJn9F6" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_9'})">5020 Зальцбург, Австрия</a>
				</template>
				<template v-slot:text>
					Одно из любимых развлечений жителей Зальцбурга в любое время года — хайкинг на гору Менхсберг (540 м над уровнем моря). Один из самых красивых маршрутов наверх стартует на набережной реки Зальцах, мимо разноцветных, словно сошедших со старинных гравюр, деревянных домиков, Кафедрального собора и крепости Хоэнзальцбург. Далее путь проходит через Старый город и площадь Моцарта, где в 1841 году возвели массивный памятник композитору. По дороге можно сделать паузу и купить в одной из пекарен ароматный трдельник — пирожок из дрожжевого теста в виде спирали, обсыпанный сахаром и корицей. Сдобное лакомство, скорее всего, придаст энергии для подъёма по множеству ступенек. 
				</template>
				<template v-slot:large>
					ВПРОЧЕМ, САМЫЙ ЗАЛЬЦБУРГСКИЙ ДЕСЕРТ НОСИТ НАЗВАНИЕ "НОКЕРЛЬ", - ЕГО ТОЖЕ ВСТРЕЧАЕШЬ В БОЛЬШИНСТВЕ ГОРОДСКИХ КАФЕ.» </template>
				<template v-slot:image>
					<div class="content__image content__image--m5"><img src="../assets/img/m-5.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--4"></div></div>
				</template>
			</Content>
			<Content :smalltitle="true" infoclass="content__info--m6" largeclass="content__large--4" :reverse="false" :short="true">
				<template v-slot:text>
					Для тех, кто хочет сэкономить время и силы, есть лифт, который доставляет прямиком наверх, к Музею современного искусства. Туннель для лифта, пробитый прямо в скале в 1767 году, — один из старейших в Европе. Но тогда вы, возможно не успеете вдоволь насладиться постепенно открывающимися видами готических башен и барочных церквей города, внесенного в список Всемирного наследия ЮНЕСКО. Этот поход можно совершать по многу раз: к вершине Менхсберга ведет множество троп (зимой дети обожают кататься вниз на санках). Некоторые известных только местным, и там редко встретишь туристов. Главное — не слишком засматриваться по сторонам: на горе есть не огороженные крутые обрывы. Если успеете добраться до смотровой площадки к полудню — попадете на долгий перезвон колоколов здешних церквей.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--m6"><img src="../assets/img/m-6.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-m3"></div></div>
				</template>
			</Content>
			<Quote class="quote quote--r">
				<template v-slot:portrait>
					<div class="quote__main-portrait quote__main-portrait--r" data-aos="fade-up" data-aos-delay="10"><img src="../assets/img/raevsky.png" alt=""></div>
				</template>
				<template v-slot:element-1>
					<div class="quote__main-element-1 quote__main-element-1--r" data-aos="fade-up" data-aos-delay="30"></div>
				</template>
				<template v-slot:element-2>
					<div class="quote__main-element-2 quote__main-element-2--r" data-aos="fade-up" data-aos-delay="50"></div>
				</template>
				<template v-slot:title>
					<div class="quote__main-title quote__main-title--r" data-aos="fade-up" data-aos-delay="70">владимир раевский</div>
				</template>
				<template v-slot:text>
					<div class="quote__info-text quote__info-text--r" data-aos="fade-up" data-aos-delay="10">В Зальцбурге уйма художественных галерей — от крупных и богатых до совсем крохотных. А над всем этим царит Музей современного искусства. «Над» — потому что находится он на горе. Забраться на гору Менхсберг можно на лифте или пешком…</div>
				</template>
				<template v-slot:button>
					<div class="quote__info-button" data-aos="fade-up" data-aos-delay="20"><router-link to="/raevsky">Читать далее</router-link></div>
				</template>
			</Quote>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Quote from '@/components/Quote'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax';
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Content,
			Burger,
			FooterBlock,
			Quote,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/motion'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (procentScroll < 10) {
					this.white = false;
				}
				if (procentScroll > 10) {
					this.white = true;
				}
				if (procentScroll > 38) {
					this.white = false;
				}
				if (procentScroll > 67) {
					this.white = true;
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
				if (procentScroll > 20) {
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_motion_25'})
							this.scroll25 = true;
						}
				}
				if (procentScroll > 40) {
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_motion_50'})
							this.scroll50 = true;
						}
				}
				if (procentScroll > 60) {
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_motion_75'})
							this.scroll75 = true;
						}
				}
				if (procentScroll > 80) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_motion_100'})
							this.scroll100 = true;
						}
				}
			},
		}
	}
</script>

<style lang="sass">
	.quote--r
		&:after
			@media screen and (max-width: 1000px)
				top: vwm(-67px)
		.quote__info-text:before
			left: vw(-57px)
			top: vw(-17px)
			@media screen and (max-width: 1000px)
				left: vwm(-43px)
				top: vwm(-19px)
	.quote__main-title--r
		margin-top: vw(208px)
		margin-left: vw(63px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(286px)
			margin-left: vwm(42px)
			margin-bottom: vwm(64px)
	.quote__main-element-1--r
		background-image: url(../assets/img/quote-el-1-r.png)
		width: vw(255px)
		height: vw(279px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(78px)
		top: vw(-105px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(255px)
			height: vwm(279px)
			left: vwm(55px)
			top: vwm(-41px)
	.quote__main-element-2--r
		background-image: url(../assets/img/quote-el-2-r.png)
		width: vw(168px)
		height: vw(136px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(-22px)
		top: vw(41px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(168px)
			height: vwm(136px)
			left: vwm(-45px)
			top: vwm(105px)
	.quote__info-text--r
		width: vw(720px)
		font-size: vw(22px)
		line-height: vw(29px)
		@media screen and (max-width: 1000px)
			margin-left: vwm(63px)
	.quote__main-portrait--r
		width: vw(185px)
		height: vw(182px)
		position: absolute
		z-index: 20
		left: vw(185px)
		top: vw(20px)
		@media screen and (max-width: 1000px)
			width: vwm(185px)
			height: vwm(182px)
			top: vwm(84px)
			left: vwm(163px)
	.bg-white
		//background-color: #fff
	.bg-blue
		//background-color: #D4E5EF
		//background-image: url(../assets/img/noise.png)
	.bg-blue-2
		//background-color: #BEDDEF
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.bg-green
		//background-color: #C9D8B6
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.collage
		&--4
			@media screen and (max-width: 1000px)
				width: vwm(354px)
				margin-left: vwm(-17px)
				margin-top: vwm(78px)
				margin-bottom: vwm(31px)
			img
				width: vw(1066px)
				height: vw(634px)
				left: vw(107px)
				top: vw(3px)
				@media screen and (max-width: 1000px)
					width: 100%
					height: auto
		&__4
			&-el-1
				position: absolute
				left: vw(-24px)
				top: vw(3px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(-9px)
				span
					width: vw(272px)
					height: vw(671px)
					background-image: url(../assets/img/collage-4-el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(122px)
						height: vwm(237px)
			&-el-2
				position: absolute
				left: vw(88px)
				top: vw(401px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(152px)
				span
					width: vw(313px)
					height: vw(239px)
					background-image: url(../assets/img/collage-4-el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 9s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(200px)
						height: vwm(100px)
			&-el-3
				position: absolute
				top: vw(349px)
				left: vw(983px)
				z-index: 5
				@media screen and (max-width: 1000px)
					left: vwm(290px)
					top: vwm(123px)
				span
					width: vw(290px)
					height: vw(312px)
					background-image: url(../assets/img/collage-4-el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(100px)
						height: vwm(200px)
			&-el-4
				position: absolute
				left: vw(992px)
				top: vw(43px)
				z-index: 8
				@media screen and (max-width: 1000px)
					top: vwm(13px)
					left: vwm(300px)
				span
					width: vw(264px)
					height: vw(266px)
					background-image: url(../assets/img/collage-4-el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(87px)
						height: vwm(157px)
			&-el-5
				position: absolute
				left: vw(220px)
				top: vw(-14px)
				z-index: 6
				@media screen and (max-width: 1000px)
					top: vwm(-33px)
					left: vwm(46px)
				span
					width: vw(269px)
					height: vw(258px)
					background-image: url(../assets/img/collage-4-el-6.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(120px)
						height: vwm(113px)
			&-el-6
				position: absolute
				left: vw(65px)
				top: vw(186px)
				z-index: 3
				@media screen and (max-width: 1000px)
					left: vwm(31px)
					top: vwm(77px)
				span
					width: vw(197px)
					height: vw(400px)
					background-image: url(../assets/img/collage-4-el-8.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(100px)
						height: vwm(100px)
		&--2
			margin-top: vw(2px)
			margin-left: vw(-44px)
			width: vw(1328px)
	.page
		&--motion
			background-color: #BEDDEF
			min-height: 100vh
			position: relative
			transition: all 300ms
			&.white
					background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1063px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 200vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: vw(1063px)
				background-image: url(../assets/img/bg-4.svg)
				background-repeat: no-repeat
				background-position: 50% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
				@media screen and (max-width: 1000px)
					height: 200vw
	.main__block
		min-height: vw(1063px)
	.collage-mob--2
		@media screen and (max-width: 1000px)
			width: vwm(378px)
			height: vwm(237px)
			background-image: url(../assets/img/collage-4-full.png)
			background-size: contain
			margin-top: vwm(107px)
			background-repeat: no-repeat
	.main__block--motion
		@media screen and (max-width: 1000px)
			min-height: 140vw !important
		@media screen and (max-width: 1000px)
			.intro__round--4
				top: -16.90278vw
				right: 25.38889vw
	.intro--4
		.intro__text
			width: vw(550px)
			@media screen and (max-width: 1000px)
				width: 100%
	.content__text
		@media screen and (max-width: 1000px)
			br
				display: none
	.content__photo-m1
		width: vw(283px)
		height: vw(271px)
		background-image: url(../assets/img/m-photo-1.png)
		background-repeat: no-repeat
		position: absolute
		background-size: contain
		bottom: vw(-50px)
		right: vw(-95px)
		position: absolute
		@media screen and (max-width: 1000px)
			width: vwm(180px)
			height: vwm(180px)

	.content__photo-m2
		width: vw(303px)
		height: vw(313px)
		background-image: url(../assets/img/m-photo-2.png)
		background-repeat: no-repeat
		position: absolute
		background-size: contain
		bottom: vw(-90px)
		left: vw(-95px)
		position: absolute
		@media screen and (max-width: 1000px)
			width: vwm(200px)
			bottom: vwm(-50px)
			height: vwm(200px)
	.content__photo-m3
		width: vw(233px)
		height: vw(276px)
		background-image: url(../assets/img/m-photo-3-1.png)
		background-repeat: no-repeat
		position: absolute
		background-size: contain
		top: vw(-90px)
		right: vw(-65px)
		position: absolute
		@media screen and (max-width: 1000px)
			width: vwm(200px)
			height: vwm(200px)
	.content__image--m5
		width: vw(558px)
		height: vw(690px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto
		img
			width: 100%
	.content__image--m2
		width: vw(518px)
		@media screen and (max-width: 1000px)
			width: 100%
	.content__text-item-m1
		margin-top: 0
	.content__image--m6
		width: vw(612px)
		height: vw(431px)
		margin-left: vw(-50px)
		@media screen and (max-width: 1000px)
			margin-left: 0
			width: 100%
			height: auto
	.content__info--m6
		padding-top: 0
		margin-top: vw(0px)
</style>