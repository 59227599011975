import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/views/Main.vue'
import Inspiration from '@/views/Inspiration.vue'
import Taste from '@/views/Taste.vue'
import Spirit from '@/views/Spirit.vue'
import Motion from '@/views/Motion.vue'
import Yakovina from '@/views/Yakovina.vue'
import Raevsky from '@/views/Raevsky.vue'
import Telnov from '@/views/Telnov.vue'
import Rastegaeva from '@/views/Rastegaeva.vue'
import Dubas from '@/views/Dubas.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
        },
        {
            path: '/inspiration',
            name: 'Inspiration',
            component: Inspiration,
        },
        {
            path: '/taste',
            name: 'Taste',
            component: Taste,
        },
        {
            path: '/motion',
            name: 'Motion',
            component: Motion,
        },
        {
            path: '/spirit',
            name: 'Spirit',
            component: Spirit,
        },
        {
            path: '/raevsky',
            name: 'Raevsky',
            component: Raevsky,
        },
        {
            path: '/yakovina',
            name: 'Yakovina',
            component: Yakovina,
        },
        {
            path: '/telnov',
            name: 'Telnov',
            component: Telnov,
        },
        {
            path: '/rastegaeva',
            name: 'Rastegaeva',
            component: Rastegaeva,
        },
        {
            path: '/dubas',
            name: 'Dubas',
            component: Dubas,
        },
    ]
})
