<template>
	<div class="content" :class="{'content--short': short}">
		<div class="container">
			<div class="content__box" :class="{'content__box--reverse': reverse}">
				<div class="content__info" data-aos="fade-up" :class="infoclass">
					<div class="content__title" v-if="hasTitleSlot" :class="{'content__title--small': smalltitle, 'content__title--large': largetitle}"><slot name="title"></slot></div>
					<div class="content__location" v-if="hasLocationSlot"><slot name="location"></slot></div>
					<div class="content__text"><slot name="text"></slot></div>
					<div class="content__large" :class="largeclass" v-if="hasLargeSlot" data-aos="fade-up"><slot name="large"></slot></div>
				</div>
				<div class="content__image" data-aos="fade-up">
					<slot name="image"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Content',
		props: {
			short: Boolean,
			reverse: Boolean,
			smalltitle: Boolean,
			largetitle: Boolean,
			color: String,
			infoclass: String,
			largeclass: String,
		},
		computed: {
			hasTitleSlot() {
				return !!this.$slots.title
			},
			hasLocationSlot() {
				return !!this.$slots.location
			},
			hasLargeSlot() {
				return !!this.$slots.large
			},
		}
	}
</script>

<style lang="sass">
	.content
		padding-top: vw(130px)
		padding-bottom: vw(50px)
		&--short
			padding-top: vw(56px)
			padding-bottom: vw(149px)
		&--t-1
			padding-top: vw(150px)
		&--t3
			padding-top: 0px !important
			margin-top: vw(-10px)
			padding-bottom: vw(179px)
		&--t6
			padding-bottom: vw(168px)
		&--t7
			padding-top: vw(149px)
			padding-bottom: vw(120px)
		&__box
			width: 100%
			display: flex
			justify-content: space-between
			&--reverse
				flex-direction: row-reverse
				.content__info
					width: vw(540px)
					margin-right: vw(58px)
					padding-top: vw(2px)
		&__large
			color: #BD242B
			font-size: vw(20px)
			text-transform: uppercase
			font-family: 'Futura Md BT'
			text-align: center
			width: vw(482px)
			margin: auto
			margin-top: vw(42px)
			letter-spacing: 0.4px
			line-height: vw(29.8px)
			&--3
				margin-top: vw(26px) !important
				width: vw(500px)
			&--4
				margin-top: vw(28px) !important
			&--5
				margin-top: vw(55px) !important
				width: vw(500px)
			&--7
				width: vw(514px)
			&--8
				width: vw(514px)
				margin-top: vw(43px)
			&--t3
				width: vw(515px)
			&--t5
				width: vw(515px)
			&--t7
				width: vw(527px)
		&__info
			width: vw(552px)
			&--3
				width: vw(581px)
				padding-bottom: vw(106px)	
			&--t2
				width: vw(530px)
				padding-bottom: vw(106px)	
			&--4
				width: vw(525px) !important
				padding-bottom: vw(106px)
				margin-right: vw(72px) !important
			&--t4
				width: vw(551px) !important
				padding-bottom: vw(106px)
				margin-right: vw(72px) !important
			&--5
				width: vw(583px) !important
				padding-bottom: vw(150px)
				margin-right: 0 !important
			&--t5
				width: vw(600px) !important
				padding-bottom: vw(126px)
				margin-right: vw(35px) !important
			&--6
				width: vw(530px) !important
				padding-bottom: vw(91px)
				margin-right: vw(66px) !important
			&--7
				width: vw(581px) !important
				padding-bottom: vw(87px)
				margin-right: 0 !important
			&--8
				width: vw(533px) !important
				padding-bottom: vw(87px)
				position: relative
				left: vw(-5px)
			&--t7
				width: vw(579px)
				padding-top: 0
				margin-top: vw(-10px)
			&--s4
				padding-top: vw(59px) !important
		&__text
			font-size: vw(17px)
			font-family: 'Futura Bk BT'
			text-align: justify
			margin-top: vw(29px)
			line-height: vw(25px)
			&-item
				&-1
					padding-top: vw(53px)
					width: 103%
		&__location
			margin-top: vw(31px)
			color: rgba(0,0,0,0.5)
			a
				color: rgba(0,0,0,0.5)
				text-decoration: none
				display: flex
				align-items: center
				text-decoration: underline
				&:before
					content: ''
					display: block
					width: vw(16px)
					height: vw(25px)
					background-image: url(../assets/img/location.svg)
					background-repeat: no-repeat
					background-size: contain
					margin-right: vw(10px)
		&__title
			font-size: vw(65px)
			line-height: vw(84px)
			font-family: 'PosteRetro'
			font-weight: normal
			color: #BD242B
			margin-top: vw(29px)
			letter-spacing: vw(4.5px)
			&--small
				font-size: vw(60px)
				letter-spacing: 4.1px
				line-height: vw(78px)
				margin-top: vw(13px)
				margin-bottom: vw(-9px)
			&--large
				line-height: vw(85px)
				margin-top: vw(83px)
		&__image
			width: vw(480px)
			margin-right: vw(68px)
			position: relative
			&--2
				width: vw(540px)
				margin-right: 0
				margin-left: vw(-20px)
			&--3
				width: vw(547px)
				margin-right: 0
				margin-top: vw(19px)
			&--4
				width: vw(558px)
				margin-left: vw(5px)
				margin-right: 0
				margin-top: vw(19px)
			&--5
				width: vw(481px) !important
				margin-left: vw(5px)
				margin-right: 0
				margin-top: vw(22px)
			&--6
				width: vw(544px) !important
				margin-left: 0px
				margin-right: 0
				margin-top: vw(21px)
				min-height: 39vw
			&--8
				width: vw(544px) !important
				margin-top: vw(21px)
			&--t1
				width: vw(541px) !important
				margin-top: vw(12px)
			&--t2
				width: vw(600px) !important
				left: vw(-50px)
				top: vw(15px)
			&--t3
				width: vw(470px) !important
				margin-left: vw(20px)
			&--t4
				width: vw(589px) !important
				margin-left: vw(-42px)
				margin-top: vw(20px)
			&--t5
				width: vw(549px) !important
				margin-left: vw(-30px)
				margin-top: vw(17px)
			&--t6
				width: vw(553px) !important
				right: vw(48px)
			&--t7
				width: vw(483px) !important
				right: vw(-37px)
			&--h1
				height: vw(580px)
			&--h2
				height: vw(530px)
			&--h3
				height: vw(634px)
			&--h4
				height: vw(595px)
			&--h5
				height: vw(690px)
			&--h6
				height: vw(607px)
			&--h7
				height: vw(692px)
			&--h8
				height: vw(605px)
				width: vw(501px)
				img
					height: 100%
			&--hy1
				width: vw(501px)
				height: vw(605px)
				margin-left: vw(-12px)
				margin-top: vw(30px)
			&--hy2
				width: vw(490px)
				height: vw(587px)
				margin-left: vw(0px)
				margin-top: vw(30px)
			&--hy3
				width: vw(576px)
				height: vw(415px)
			&--hy4
				width: vw(501px)
				height: vw(605px)
				margin-top: vw(42px) !important
				margin-left: vw(15px) !important
			&--hy5
				width: vw(540px)
				height: vw(470px)
				margin-top: vw(42px) !important
				margin-left: vw(0px) !important
			&--s1
				height: vw(580px)
			&--text
				width: vw(444px)
				margin-top: vw(51px)
				margin-left: vw(18px)
				min-height: 1vw !important
			&--mob
				display: none
			img
				width: 100%
		&--y-1
			padding-top: vw(77px)
		&--y-2
			padding-top: vw(37px)
			.content__text
				margin-top: 1.3vw
				margin-left: -2.7vw
				width: vw(550px)
			.content__large
				width: vw(439px)
				margin-left: vw(22px)
		&--y-3
			padding-top: vw(90px)
			&-1
				padding-top: vw(193px)
				.content__large
					width: 35vw !important
			.content__info
				padding-top: vw(27px)
				margin-right: 6.4vw
				width: 37.8vw
			.content__location
				margin-top: 2.7vw
			.content__text
				font-family: 'Futura PT'
			.content__large
				width: 30vw
				margin-top: 2.8vw !important
		&--y-4
			padding-top: vw(0px)
			margin-top: vw(-20px)
			&-1
				margin-top: vw(-10px) 
			.content__image
				margin-right: vw(95px)
				margin-left: 0
				margin-top: vw(-8px)
			.content__text
				margin-top: vw(37px)
				width: 36.8vw
				font-family: 'Futura PT'
		&--y-5
			padding-top: 13vw
			.content__image
				margin-right: vw(95px)
				margin-left: 0
				margin-top: vw(-8px)
			.content__text
				margin-top: vw(37px)
				width: 36.8vw
		&--y-6
			padding-top: 1.2vw
			.content__image
				margin-left: 0
				margin-top: vw(-8px)
			.content__text
				margin-top: vw(43px)
				width: 38.8vw
				margin-left: vw(-37px)
		&--y-7
			padding-top: 9.9vw
			.content__text
				font-family: 'Futura PT'
				padding-right: 0.5vw
			.content__info
				margin-right: 4.7vw
				width: 39.5vw
			.content__image
				margin-top: vw(34px)
		&--y-8
			padding-top: 12.4vw
			.content__title
				padding-top: 5.6vw
			.content__text
				padding-right: 3.5vw
			.content__info
				margin-right: 4.7vw
				width: 36.5vw
			.content__image
				margin-top: vw(34px)
				width: vw(515px)
				height: vw(620px)
				margin-right: vw(57px)
		&--y-9
			padding-top: 2.7vw
			//.content__title
			//	padding-top: 5.6vw
			//.content__text
			//	padding-right: 3.5vw
			.content__info
				width: 38.6vw
				margin-right: 5.52778vw
			.content__image
				width: vw(540px)
				height: vw(481px)
				margin-left: vw(-15px)

	/*elements*/
	.content
		&__round
			width: vw(156px)
			height: vw(156px)
			background-image: url(../assets/img/round-text-2.svg)
			background-size: contain
			position: absolute
			bottom: vw(-74px)
			left: vw(-80px)
			animation: rotateRound
			animation-duration: 10s
			animation-timing-function: linear
			animation-iteration-count: infinite
			-webkit-backface-visibility: hidden
			&--t
				background-image: url(../assets/img/round-text-3.svg)
			&--s
				background-image: url(../assets/img/round-text-4.svg)
			&--m
				background-image: url(../assets/img/round-text-5.svg)
			&--y
				background-image: url(../assets/img/yakovina/round.svg)
			&--db
				background-image: url(../assets/img/dubas/r.svg)
			&--2
				bottom: auto
				top: vw(-76px)
			&--4
				left: auto
				right: vw(-75px)
			&--5
				left: auto
				right: vw(-75px)
				bottom: auto
				top: vw(-76px)
			&--6
				left: auto
				right: vw(438px)
				bottom: auto
				top: vw(-79px)
		&__photo-1
			width: vw(345px)
			height: vw(234px)
			background-image: url(../assets/img/photo-1.png)
			background-size: contain
			position: absolute
			bottom: vw(-104px)
			right: vw(-68px)
		&__photo-2
			width: vw(236px)
			height: vw(350px)
			background-image: url(../assets/img/photo-2.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-143px)
			left: vw(-40px)
		&__clouds
			width: vw(396px)
			height: vw(304px)
			background-image: url(../assets/img/photo-3.png)
			background-size: contain
			position: absolute
			bottom: vw(-99px)
			right: vw(-62px)
		&__photo-4
			width: vw(501px)
			height: vw(300px)
			background-image: url(../assets/img/photo-4.png)
			background-size: contain
			position: absolute
			bottom: vw(-188px)
			left: vw(-60px)
		&__man
			width: vw(286px)
			height: vw(383px)
			background-image: url(../assets/img/man.png)
			background-size: contain
			position: absolute
			bottom: vw(-119px)
			left: vw(-68px)
			background-repeat: no-repeat
		&__pointer
			width: vw(171px)
			height: vw(294px)
			background-image: url(../assets/img/collage-3-el-5.png)
			background-size: contain
			position: absolute
			bottom: vw(25px)
			left: vw(-50px)
			background-repeat: no-repeat
			@media screen and (max-width: 1000px)
				display: none
		&__people
			width: vw(325px)
			height: vw(205px)
			background-image: url(../assets/img/people.png)
			background-size: contain
			position: absolute
			bottom: vw(-76px)
			right: vw(-34px)
			background-repeat: no-repeat
			z-index: 10
			@media screen and (max-width: 1000px)
				display: none
		&__ice
			width: vw(297px)
			height: vw(262px)
			background-image: url(../assets/img/ice.png)
			background-size: contain
			position: absolute
			bottom: vw(-93px)
			left: vw(-54px)
			background-repeat: no-repeat
		&__carnaval
			width: vw(231px)
			height: vw(368px)
			background-image: url(../assets/img/carnaval.png)
			background-size: contain
			position: absolute
			bottom: vw(-76px)
			left: vw(-54px)
			background-repeat: no-repeat
		&__rose
			width: vw(232px)
			height: vw(226px)
			background-image: url(../assets/img/rose.png)
			background-size: contain
			position: absolute
			bottom: vw(-81px)
			right: vw(-40px)
		&__deamon
			width: vw(363px)
			height: vw(312px)
			background-image: url(../assets/img/deamon.png)
			background-size: contain
			position: absolute
			bottom: vw(-65px)
			left: vw(-66px)
		&__picture
			width: vw(285px)
			height: vw(285px)
			background-image: url(../assets/img/picture.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-165px)
			right: vw(-66px)
		&__y-1
			width: vw(173px)
			height: vw(372px)
			background-image: url(../assets/img/yakovina/el-1.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-76px)
			right: vw(-79px)
		&__y-2
			width: vw(297px)
			height: vw(204px)
			background-image: url(../assets/img/yakovina/el-2.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-60px)
			left: vw(-45px)
		&__y-3
			width: vw(382px)
			height: vw(280px)
			background-image: url(../assets/img/yakovina/el-3.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-89px)
			right: vw(-114px)
		&__y-4
			width: vw(308px)
			height: vw(184px)
			background-image: url(../assets/img/yakovina/el-4.png)
			background-size: contain
			background-repeat: no-repeat
			position: absolute
			bottom: vw(-59px)
			left: vw(-34px)

	@media screen and (max-width: 1000px)
		.content__image
			min-height: 80vw
		.content__box
			flex-direction: column
			padding-left: vwm(15px)
			padding-right: vwm(15px)
		.content__title
			font-size: vwm(40px)
			letter-spacing: vwm(4px)
			line-height: vwm(44px)
			br
				display: none
		.content__info
			width: 100%
			margin-bottom: vwm(26px)
		.content__location:before
			width: vwm(16px)
			height: vwm(25px)
			margin-right: vwm(10px)
		.content__location
			font-size: vwm(16px)
			margin-top: vwm(20px)
		.content__text
			font-size: vwm(17px)
			line-height: vwm(23px)
			margin-top: vwm(31px)
			text-align: left
		.content__image
			width: 100%
			margin-right: 0
			margin-left: 0
			height: auto
		.content__image--desk
			display: none
		.content__image--mob
			display: block
		.content__round
			display: none
		.content__rose
			width: vwm(141px)
			height: vwm(136px)
			bottom: vwm(-17px)
		.content__box--reverse .content__info
			width: 100%
			margin-right: 0
			padding-top: 0
		.content__large
			font-size: vwm(20px)
			line-height: vwm(30px)
			width: 100%
			margin-top: vwm(30px)
			br
				display: none
		.content
			padding-top: vwm(70px)
			padding-bottom: vwm(60px)
		.content--short
			padding-top: 0
			padding-bottom: vwm(70px)
			margin-top: vwm(-43px)
		.content__large--3
			margin-top: vwm(29px) !important
		.content__image--3
			margin-top: 0
		.content__info--3
			padding-bottom: vwm(4px)
		.content__info--4
			width: 100% !important
		.content__large--4
			margin-top: vwm(29px) !important
		.content__image--4
			margin-top: vwm(-23px)
		.content__deamon
			width: vwm(197px)
			height: vwm(170px)
			left: vwm(158px)
			bottom: vwm(-12px)
		.content__info--5
			width: 100% !important
		.content__large--5
			margin-top: vwm(30px) !important
		.content__image--5
			width: 100% !important
			margin-top: vwm(-35px)
		.content__ice
			width: vwm(178px)
			height: vwm(160px)
			bottom: vwm(-50px)
			left: vwm(-10px)
		.content__info--6
			width: 100% !important
		.content__image--6
			width: 100% !important
			margin-right: 0 !important
			margin-top: vwm(-21px)
		.content__carnaval
			width: vwm(125px)
			height: vwm(198px)
			left: vwm(230px)
		.content__info--7
			width: 100% !important
		.content__info--8
			width: 100% !important
		.content__image--8
			width: 100% !important
			margin-top: vwm(-19px)
		.content__man
			width: vwm(181px)
			height: vwm(242px)
			left: vwm(173px)
		.content__image--t1
			width: 100% !important
		.content__image--t2
			width: 100% !important
			left: 0
		.content__image--t3
			width: 100% !important
		.content__info--t4
			width: 100% !important
		.content__image--t4
			width: 100% !important
		.content__info--t5
			width: 100% !important
		.content__image--t5
			width: 100% !important
		.content__image--t6
			width: 100% !important
			right: 0
		.content__image--t7
			width: 100% !important
			right: 0
		.content__photo-1
			width: vwm(142px)
			height: vwm(96px)
			right: vwm(10px)
		.content__photo-2
			width: vwm(119px)
			height: vwm(176px)
		.content__clouds
			width: vwm(163px)
			height: vwm(125px)
			right: vwm(10px)
		.content__photo-4
			width: vwm(197px)
			height: vwm(118px)
			left: vwm(4px)
		.content__y-1
			width: vwm(97px)
			height: vwm(198px)
			right: 0
		.content--y-2 .content__text
			width: 100%
			margin-left: 0
		.content--y-2 .content__large
			width: 100%
			margin-left: 0 !important
		.content__large--3
			width: 100% !important
		.content--y-4 .content__text
			width: 100% !important
		.content--y-3
			padding-top: 0vw !important
		.content--y-4
			padding-top: 0 !important
		.content--y-5
			padding-top: 0 !important
		.content--y-5 .content__text
			width: 100% !important
		.content--y-6 .content__text
			width: 100%
			margin-left: 0
		.content__y-2
			width: 54vw
			height: 37vw
		.content--y-7
			padding-top: 0 !important
		.content--y-8
			padding-top: 0 !important
		.content--y-8 .content__image
			width: 100%
		.content--y-9 .content__image
			margin-left: 0
			width: 100%
		.content__y-3
			width: 50vw
			height: 50vw
			right: 0
			top: 83vw
		.content__y-4
			width: 50vw
			height: 50vw
			right: 0
			top: 53vw
		.content--y-3-1 .content__large
			width: 100% !important
</style>