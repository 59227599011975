<template>
	<div>
		<div class="main__header">
			<div class="main__header-label">Специальный проект</div>
			<div class="main__header-logo"><a href="javascript:void(0)" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5886042447&iu=/81006599/hmiru-mc/count')"></a></div>
			<div class="main__header-x">x</div>
			<div class="main__header-menu" v-if="!secondMenu">
				<div class="main__header-menu-item main__header-menu-item--4"><router-link to="/dubas">Алекс Дубас</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--2"><router-link to="/raevsky">Владимир Раевский</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--5"><router-link to="/yakovina">Ольга Яковина</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--3"><router-link to="/telnov">Дмитрий Тельнов</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--4"><router-link to="/rastegaeva">Ольга Растегаева</router-link></div>
			</div>
			<div class="main__header-menu" v-else>
				<div class="main__header-menu-item main__header-menu-item--10"><router-link to="/">Главная</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--6" :class="{active: active == 1}"><router-link to="/taste" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_taste'})">вкус</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--7" :class="{active: active == 2}"><router-link to="/motion" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_motion'})">движение</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--8" :class="{active: active == 3}"><router-link to="/spirit" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_spirit'})">дух</router-link></div>
				<div class="main__header-menu-item main__header-menu-item--9" :class="{active: active == 4}"><router-link to="/inspiration" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_inspiration'})">вдохновение</router-link></div>
			</div>
			<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_mc'})"></a></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HeaderBlock',
		props: {
			secondMenu: Boolean,
			active: Number,
		},
		methods: {
			openLink(link) {
				window.open(link, '_blank').focus();
				this.$gtag.event('click', {event_category: 'link','event_label': 'link_austria'})
			}
		}
	}
</script>

<style lang="sass">
	.main__header
		&-menu-item--1 a
			&:after
				background-image: url(../assets/img/line-friends-1.svg) !important
		&-menu-item--2 a
			&:after
				background-image: url(../assets/img/line-friends-2.svg) !important
		&-menu-item--3 a
			&:after
				background-image: url(../assets/img/line-friends-3.svg) !important
		&-menu-item--4 a
			&:after
				background-image: url(../assets/img/line-friends-4.svg) !important
		&-menu-item--5 a
			&:after
				background-image: url(../assets/img/line-friends-5.svg) !important
		&-menu-item--6 a
			&:after
				background-image: url(../assets/img/line-menu-1.svg) !important
		&-menu-item--7 a
			&:after
				background-image: url(../assets/img/line-menu-2.svg) !important
		&-menu-item--8 a
			&:after
				background-image: url(../assets/img/line-menu-3.svg) !important
		&-menu-item--9 a
			&:after
				background-image: url(../assets/img/line-menu-4.svg) !important
		&-menu-item--10 a
			&:after
				background-image: url(../assets/img/line-menu-2.svg) !important
	.header__nav--inner
		.main__header
			width: vw(1260px)
			margin: auto
		.main__header-menu
			margin: auto
			position: relative
			left: 1.7vw
			top: -0.2vw
		.main__header-mc
			left: 1vw
		.main__header-logo
			position: relative
			left: 1.7vw
			top: -0.2vw
		.main__header-menu-item
			margin-left: vw(30px)
			margin-right: vw(30px)
			a
				text-transform: uppercase
				font-size: vw(20px)
	.header
		&__nav
			position: fixed
			top: 0
			left: 0
			background-color: #fff
			height: vw(80px)
			display: flex
			width: 100%
			justify-content: center
			align-items: center
			z-index: 10000
			padding-right: 34px
			@media screen and (max-width: 1000px)
				display: none
			.main__header
				padding-top: 0
				@media screen and (max-width: 1000px)
					display: none
	.main__header-menu-item.active a
		color: #C54142
		cursor: default
		&:after
			opacity: 0 !important
</style>