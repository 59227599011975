<template>
	<div class="page page--spirit" :class="{white: white}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="3" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block main__block--spirit">
			<div class="page__header">
				<HeaderBlock />
			</div>
			<Intro class="intro--2">
				<template v-slot:title>
					дух австрии
				</template>
				<template v-slot:text>
					Слиться с природой и почувствовать смену перспективы: разреженная атмосфера Альп располагает к этому! Кажется, любому равновесию - даже душевному - предшествует замедление.
				</template>
				<template v-slot:collage>
					<div class="collage collage--3">
						<img src="../assets/img/collage-3.png" alt="">
						<div class="collage__3-el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__3-el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__3-el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__3-el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__3-el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__3-el-6" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--spirit collage--mob"></div>-->
					<div class="intro__round intro__round--3 intro__round--s"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--t-1">
				<template v-slot:title>
					бесконечный <br>релакс
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/hVS953p1y7kQ8GX1A" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_10'})">Kühtai 40, 6183 Silz, Австрия</a>
				</template>
				<template v-slot:text>
					Представьте: вы погружаетесь в теплую воду, оглядываетесь, и — не верите своим глазам. Над вами — усыпанное звездами небо, а вокруг, как в сказке, — темные очертания заснеженных гор. Бассейн с эффектом инфинити под названием The Endless Sky Pool при пятизвездочном отеле Mooshaus на горнолыжном курорте Кютай находится на высоте 2050 метров. Это самый высоко расположенный бассейн в Альпах. Его длина — 100 метров, хотя по ощущениям бассейн действительно кажется бесконечным. 
				</template>
				<template v-slot:large>
					Sky Pool находится на пятом этаже нового здания отеля — и дважды <br>в неделю открыт для ночного купания!
				</template>
				<template v-slot:image>
					<div class="content__image content__image--s1"><img src="../assets/img/s-2.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""></div>
				</template>
			</Content>
			<Content :short="true" :reverse="true">
				<template v-slot:text>
					<div class="content__text-item-s1">
						Этот необычный бассейн доступен только для взрослых, зато в велнесс-центре отеля Mooshaus множество развлечений для всей семьи. Для самых маленьких недавно появился baby pool, для детей постарше есть большой подогреваемый бассейн с водными горками, джакузи и гидромассажем, а также несколько саун. Еще юные гости могут записаться на массаж, уход за лицом и другие СПА-процедуры, посмотреть фильм в отельном мини-кинотеатре и отведать домашней еды по специальному детскому меню.
						<div class="serpator"></div>
						Но ярче всего дух Австрии можно прочувствовать, отведав всей семьей традиционное фондю в принадлежащей отелю горной хижине Drei Seen Hütte на высоте 2,334 метров. А в самом Кютае, расположенном всего в получасе езды от Инсбрука, — более 40 км на все уровни, включая беговые лыжи и санные трассы, фан-парк, несколько горнолыжных школ, большая зона катания для начинающих и возможность спуститься на лыжах прямо к дверям отеля.
					</div>
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t1"><img src="../assets/img/s-3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--5 content__round--s"></div></div>
				</template>
			</Content>
			<Quote class="quote quote--s">
				<template v-slot:portrait>
					<div class="quote__main-portrait quote__main-portrait--s" data-aos="fade-up" data-aos-delay="10"><img src="../assets/img/telnov.png" alt=""></div>
				</template>
				<template v-slot:element-1>
					<div class="quote__main-element-1 quote__main-element-1--s" data-aos="fade-up" data-aos-delay="30"></div>
				</template>
				<template v-slot:element-2>
					<div class="quote__main-element-2 quote__main-element-2--s" data-aos="fade-up" data-aos-delay="50"></div>
				</template>
				<template v-slot:title>
					<div class="quote__main-title quote__main-title--s" data-aos="fade-up" data-aos-delay="70">дмитрий тельнов</div>
				</template>
				<template v-slot:text>
					<div class="quote__info-text quote__info-text--s" data-aos="fade-up" data-aos-delay="10">Вершина горы Унтерсберг служит границей между немецкой Баварией и Австрией. Высящийся чуть в стороне от города горный массив жители Зальцбурга любовно называют «домашней горой», а национальный парк, расположенный здесь, многие годы является излюбленным местом отдыха горожан…</div>
				</template>
				<template v-slot:button>
					<div class="quote__info-button" data-aos="fade-up" data-aos-delay="20"><router-link to="/telnov">Читать далее</router-link></div>
				</template>
			</Quote>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t2" largeclass="content__large--t2" class="content--spirit">
				<template v-slot:title>
					водный дом
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/De9d1DAhuFA8NCE89" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_11'})">Längenfeld 6444, Австрия</a>
				</template>
				<template v-slot:text>
					Альпийская деревушка Ленгенфельд находится в сердце Тироля, в центре долины Эцталь на высоте 1180 метров. Долину окружают ледники и целых 250 вершин-трехтысячников — курорты Зёльден и Обергургль-Хохгургль совсем рядом. Летом тут отличные возможности для треккинга, рафтинга, катания на горных велосипедах и прочих активных развлечений. Но сюда с давних времен едут со всей Европы еще и за оздоровлением — в Лангенфельде из-под земли бьют горячие термальные источники. 
				</template>
				<template v-slot:large>
					ОНИ БОГАТЫ СЕРОЙ И СПОСОБСТВУЮТ УМЕНЬШЕНИЮ ПРОБЛЕМ С СЕРДЦЕМ, СУСТАВАМИ И ОПОРНО-ДВИГАТЕЛЬНЫМ АППАРАТОМ, А ТАКЖЕ ПОМОГАЮТ ВОССТАНОВИТЬСЯ ПОСЛЕ ТРАВМ.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t2"><img src="../assets/img/s-4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""></div>
				</template>
			</Content>
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t3" largeclass="content__large--t3" :reverse="true" class="content--t3">
				<template v-slot:text>
					Несколько лет назад в Лангенфельде открылся один из лучших термальных комплексов во всем Тироле — футуристический СПА-отель “Аква-дом” площадью около 50 000 кв. м. Здесь 12 бассейнов с термальной водой максимально комфортной для тела температуры — 34-36 градусов. Три открытых бассейна — возвышающиеся над землей большие круглые чаши. В одной из чаш — вода с морской солью, в другую — каждые 15 минут поступает, смешиваясь с термальной водой, сульфидная минеральная вода. В третьей — под водой играет расслабляющая музыка и работает гидромассаж. Бассейны внутри накрывает гигантский стеклянный купол с панорамой гор. Вместе с чашами эта минималистичная, выполненная из дерева, стекла и камня, конструкция гармонично вписались в ландшафт Эцтальских Альп.<div class="serpator"></div>В “Аква-доме” можно запросто провести несколько дней подряд или даже неделю: как следует погреться в саунах и банях, сделать соляной скраб, укрепить лимфоток вен в лечебной зоне Кнайпа и просто помедитировать на горы у камина за чашечкой-другой свежезаваренного чая из Альпийских трав. 
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t3 content__image--h2"><img src="../assets/img/s-5.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--5 content__round--s"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--s4" largeclass="content__large--4" :reverse="true" class="content--spirit">
				<template v-slot:title>
					кроме лыж
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/bWHhG5LEuNbUBz3j7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_12'})">Rohr 23, 6280 Zell am Ziller, Австрия</a>
				</template>
				<template v-slot:text>
					В долине Циллерталь в Тироле целых 542 километра горнолыжных трасс, 180 подъемников и четыре зоны катания. Из них самые известные — Циллерталь Арена и Майрхофен. Но и у тех, кто не катается на горных лыжах, в этих краях масса опций для активного отдыха. Любителям адреналиновых ощущений стоит попробовать первые в Альпах снежные “американские горки” — тобоган Arena Coaster у нижней станции подъемника Циллерталь Арена в Целле. <div class="serpator"></div> Детям понравится спуститься с ветерком на санках по 7-ми километровой трассе в Хайнценберге. Спуск может занять около часа: это самая длинная санная трасса в Циллертале с перепадом высот 715 метров.
				</template>
				<template v-slot:large>
					Особое удовольствие катиться при свете звезд — несколько дней в неделю трасса открыта до позднего вечера. </template>
				<template v-slot:image>
					<div class="content__image content__image--t4"><img src="../assets/img/s-6.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__pointer" data-aos="zoom-in"></div></div>
				</template>
			</Content>
			<Content :smalltitle="true" infoclass="content__info--t5" largeclass="content__large--t5" :reverse="false" class="content--spirit2">
				<template v-slot:text>
					Тем, кто предпочитает более спокойные развлечения, можно подняться на снегоступах — с гидом или самостоятельно — на высоту 1650 к базовому лагерю в хижине Герлосштайн. Походы на снегоступах — почти как медитация: вы двигаетесь в своем ритме, в полной тишине по нетронутому пушистому снегу. Этот вид активного досуга доступен в любом возрасте и даже тем, кто редко занимается спортом. <div class="serpator"></div> А для предпочитающих скорость и нагрузку в Циллертале немало трасс для беговых лыж. Помимо классической и коньковой трассы на плато Герлосплате (1640 м) находится самая высокогорная лыжня, проходящая по заповеднику «Хоэ Тауэрн». А если погода вдруг испортится или, например, вы приедете в Тироль с малышами, обязательно покатайтесь на старинном паровозе Циллертальской железной дороги.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t5"><img src="../assets/img/s-7.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__people" data-aos="zoom-in"></div><div class="content__round content__round--6 content__round--s"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Quote from '@/components/Quote'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax';
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Content,
			Burger,
			FooterBlock,
			Quote,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/spirit'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				console.log(val)
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (procentScroll < 10) {
					this.white = false;
				}
				if (procentScroll > 10) {
					this.white = true;
				}
				if (procentScroll > 44) {
					this.white = false;
				}
				if (procentScroll > 67) {
					this.white = true;
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
				if (procentScroll > 20) {
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_spirit_25'})
							this.scroll25 = true;
						}
				}
				if (procentScroll > 40) {
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_spirit_50'})
							this.scroll50 = true;
						}
				}
				if (procentScroll > 60) {
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_spirit_75'})
							this.scroll75 = true;
						}
				}
				if (procentScroll > 80) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_spirit_100'})
							this.scroll100 = true;
						}
				}
			},
		}
	}
</script>

<style lang="sass">
	.quote--s
		&:after
			@media screen and (max-width: 1000px)
				top: vwm(-72px)
		.quote__info-text:before
			left: vw(-43px)
			top: vw(-17px)
			@media screen and (max-width: 1000px)
				left: vwm(-43px)
				top: vwm(-17px)
	.quote__main-title--s
		margin-top: vw(206px)
		margin-left: vw(112px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(234px)
			margin-left: vwm(55px)
			margin-bottom: vwm(65px)
	.quote__main-element-1--s
		background-image: url(../assets/img/quote-el-1-s.png)
		width: vw(246px)
		height: vw(261px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(87px)
		top: vw(-69px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(246px)
			height: vwm(261px)
			left: vwm(40px)
			top: vwm(-53px)
	.quote__main-element-2--s
		background-image: url(../assets/img/quote-el-2-s.png)
		width: vw(145px)
		height: vw(105px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(55px)
		top: vw(-13px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(145px)
			height: vwm(105px)
			left: vwm(8px)
			top: vwm(2px)
	.quote__info-text--s
		width: vw(730px)
		@media screen and (max-width: 1000px)
			margin-left: vwm(62px)
	.quote__main-portrait--s
		width: vw(180px)
		height: vw(172px)
		position: absolute
		z-index: 20
		left: vw(209px)
		top: vw(20px)
		@media screen and (max-width: 1000px)
			width: vwm(180px)
			height: vwm(172px)
			left: vwm(161px)
			top: vwm(35px)
	.collage
		&__3
			&-el-1
				position: absolute
				left: vw(-64px)
				top: 92px
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(0px)
					left: vwm(-10px)
				span
					width: vw(257px)
					height: vw(268px)
					background-image: url(../assets/img/collage-3-el-1.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDownAlt
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(210px)
						height: vwm(104px)
			&-el-2
				position: absolute
				left: vw(-40px)
				top: vw(417px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(-2px)
					top: vwm(153px)
				span
					width: vw(307px)
					height: vw(362px)
					background-image: url(../assets/img/collage-3-el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 9s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(100px)
						height: vwm(200px)
			&-el-3
				position: absolute
				left: vw(89px)
				top: vw(347px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(57px)
					top: vwm(83px)
				span
					width: vw(168px)
					height: vw(288px)
					background-image: url(../assets/img/collage-3-el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(200px)
						height: vwm(121px)
			&-el-4
				position: absolute
				left: vw(943px)
				top: vw(399px)
				z-index: 8
				@media screen and (max-width: 1000px)
					left: vwm(268px)
					top: vwm(114px)
				span
					width: vw(323px)
					height: vw(365px)
					background-image: url(../assets/img/collage-3-el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(171px)
						height: vwm(135px)
			&-el-5
				position: absolute
				left: vw(879px)
				top: vw(522px)
				z-index: 10
				@media screen and (max-width: 1000px)
					left: vwm(235px)
					top: vwm(145px)
				span
					width: vw(127px)
					height: vw(228px)
					background-image: url(../assets/img/collage-3-el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(60px)
						height: vwm(182px)
		&--3
			margin-top: vw(-114px)
			@media screen and (max-width: 1000px)
				width: vwm(377px)
				margin-top: vwm(51px)
	.page
		&--spirit
			background-color: #C0DCE7
			min-height: 100vh
			transition: all 300ms
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1120px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/spirit.svg)
				background-repeat: no-repeat
				background-position: 50% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
	.main__block--spirit
		min-height: vw(1020px)
	.content__text-item-s1
		padding-top: vw(0px)
		margin-top: vw(-8px)
	@media screen and (max-width: 1000px)
		.collage-mob--spirit
			background-image: url(../assets/img/collage-3-full.png)
			width: vwm(370px)
			height: vwm(237px)
			background-size: contain
			margin-top: vwm(45px)
			background-repeat: no-repeat
		.intro__round--s
			top: vw(-78px)
			right: vw(256px)
		.main__block--spirit
			min-height: vw(2400px) !important
		.content--spirit
			padding-bottom: vw(20px)
		.content--spirit2
			padding-top: vw(20px)
</style>