<template>
	<div class="page page--taste" :class="{white: white, blue: blue}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="1" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block main__block--taste">
			<div class="page__header">
				<HeaderBlock />
			</div>
			<Intro class="intro--2">
				<template v-slot:title>
					вкус австрии
				</template>
				<template v-slot:text>
					Истории людей - от Бургенланда до Форальберга - которые производят эко-продукты с душой и любовью к своей стране, а также рекомендации мест - в Альпах и не только - где кухня и атмосфера запомнятся на всю жизнь.
				</template>
				<template v-slot:collage>
					<div class="collage collage--2">
						<img src="../assets/img/collage-2.png" alt="">
						<div class="collage__2-el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__2-el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__2-el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__2-el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__2-el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__2-el-6" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--2 collage--mob"></div>-->
					<div class="intro__round intro__round--2"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--t-1">
				<template v-slot:title>
					чистый вкус
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/PJD9yGnxcvrAedcV9" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_1'})">Schafflerhofstraße 156, 1220 Wien, Австрия</a>
				</template>
				<template v-slot:text>
					Крупные, сочные помидоры “бычье сердце” от blün на вкус напоминают рыночные помидоры из бабушкиного салата из детства. Название венского стартапа по производству эко-овощей и рыбы - это сочетание двух главных в природе цветов - “синий” (blau) и “зеленый” (grun). <div class="serpator"></div>
					Небольшой бизнес на окраине австрийской столицы пять лет назад запустили четыре друга с большим опытом в садоводстве и пищевой индустрии и с еще бОльшими амбициями сделать мир лучше. 
				</template>
				<template v-slot:large>
					<div class="content__image content__image--text"><img src="../assets/img/text.png" alt=""></div>
				</template>
				<template v-slot:image>
					<div class="content__image content__image--h1"><img src="../assets/img/t-2.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__photo-1" data-aos="zoom-in"></div><div class="content__round content__round--1 content__round--t"></div></div>
				</template>
			</Content>
			<Content :short="true" :reverse="true">
				<template v-slot:text>
					<div class="content__text-item-1">
						Это технология замкнутой экосистемы, в которой все перерабатывается и используется. Например, окуней, форелей, угрей, судака и прочую рыбу здесь выращивают в чистой, без примесей тяжелых металлов<br/>и прочих токсичных веществ воде с предгорий Альп. Этой же водой удобряют овощи в соседних теплицах. Помощниками у blün работают пчелы: в теплицах они опыляют помидоры, паприку и баклажаны. 
						<div class="serpator"></div>
						Продукция стартапа есть в меню уже 50 венских ресторанов, включая гастрономическое заведение «Лабштелле» в центре города. Очень вкусный кетчуп, который в blün делают из излишков помидоров, можно купить в крупном венском магазине Wein & Co, в мелких фермерских лавках, на ярмарках, а также в магазине при самом предприятии, где всегда в наличии свежая и копченая рыба и прочий эко-ассортимент.
					</div>
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t1"><img src="../assets/img/t-3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""></div>
				</template>
			</Content>
		</div>
		<div class="bg-green">
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t2" largeclass="content__large--t2">
				<template v-slot:title>
					масло <br/>с мельницы
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/E7AXBCJGR4F6Eq9f9" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_2'})">Erzherzog-Karl-Ring 17, 3730 Eggenburg, Австрия</a>
				</template>
				<template v-slot:text>
					Городок Эггенбург в районе Вайнфиртель, знаменитом своими винами и эко-фермами, находится в Нижней Австрии, в 75 км к северу от Вены. Старинная мельница, принадлежащая семейству Гилли, - памятник истории. Мельнице почти пять веков - только сейчас здесь производят не муку, а экологически чистое масло. <div class="serpator"></div> Владелец маслобойни - обаятельный Георг Гилли, отец трех дочек,<br/>в прошлом - беспечный путешественник-серфер, объездивший полмира в поисках самой крутой волны. Восемь лет назад Гилли вернулся в Австрию, отреставрировал пострадавшую от пожара мельницу и построил там маслобойню. 
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t2"><img src="../assets/img/t-4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""></div>
				</template>
			</Content>
			<Content :smalltitle="false" :largetitle="true" infoclass="content__info--t3" largeclass="content__large--t3" :reverse="true" class="content--t3">
				<template v-slot:text>
					Сейчас масло холодного отжима от Георга Гилли продается не только в эко-лавках в Нижней Австрии и Вене, но и через интернет. Сырье Гилли закупает у фермеров в радиусе не больше 30 км. С большинством его поставщиков сотрудничали еще отец, дед и прадед Гилли. <div class="serpator"></div>Масло производится шести сортов: классические подсолнечное, тыквенное и сафлоровое, а также рыжиковое (масло из масличного растения семейства крестоцветных, с нотками гороха или спаржи и легким ореховым послевкусием), конопляное и льняное. Последнее выжимают из семечек эцтальского льна высочайшего качества. Это пряное и свежее на вкус масло всегда на столе у самого хозяина маслобойни: он любит добавлять его в мюсли, йогурт или смузи. И конечно, Георг Гилли всегда рад гостям. 
				</template>
				<template v-slot:large>
					Он организует дегустацию своей продукции, познакомит с семьей,<br/>а также проведет экскурсию по мельнице, которую считает еще одним членом своей большой семьи.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--t3 content__image--h2"><img src="../assets/img/t-5.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__photo-2" data-aos="fade-left" data-aos-delay="400"></div><div class="content__round content__round--5 content__round--t"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--t4" largeclass="content__large--4" :reverse="false">
				<template v-slot:title>
					гурме <br>на леднике
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/kEqDFp8LPED2Z5ZU7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_3'})">Stubaier Gletscher, Mutterberg 2, 6167 Neustift im Stubaital, Австрия</a>
				</template>
				<template v-slot:text>
					В Австрийских Альпах высота  - не помеха гастрономическим удовольствиям. Например, ресторан «Шауфельшпитц» (Schaufelspitz) на леднике горнолыжного курорта Штубай в окрестностях Инсбрука находится на высоте 3000 метров! Одно из самых высокогорных заведений в мире отмечено в главных кулинарных гидах. Кухней здесь заправляет уроженец Инсбрука, красавец-шеф Дэвид Костнер. Несмотря на молодой возраст, он успел проработать в лучших гастрономических ресторанах Европы, в том числе со звездой Мишлен. Клецки со шпиком, яйца пашот с сезонным трюфелем, оленину под соусом из лисичек и прочие тирольские блюда в авторской подаче сервируют в зале с отделкой из кедровой сосны и уютно потрескивающим камином. 
				</template>
				<template v-slot:large>
					Аперитив или гурме-завтрак можно заказать и на залитой солнцем террасе - ясных дней в долине Штубайталь<br/>выдается немало. </template>
				<template v-slot:image>
					<div class="content__image content__image--t4"><img src="../assets/img/t-6.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--2"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="true" infoclass="content__info--t5" largeclass="content__large--t5" :reverse="true">
				<template v-slot:title>
					выше только <br/>звезды
				</template>
				<template v-slot:location>
					<a href="https://g.page/albergo-hochzillertal?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_4'})">Skigebiet Hochzillertal, NUR IM WINTER GEÖFFNET Bergstation WIMBACHEXPRESS, 6272 Kaltenbach, Австрия</a>
				</template>
				<template v-slot:text>
					К дизайнерскому отелю-ресторану “Альберго” в долине Циллерталь на высоте 2400 метров доставляет новый скоростной подъемник Wimbachexpress.<br/>В местном гастрономическом ресторане - итальянская кухня с тирольским акцентом. Это заведение недавно удостоилось награды лучшего горного ресторана в мире. Традиционную австрийскую кухню можно попробовать<br/>и в “гурме-хижине” “Ведельхутте" (Wedelhuette). В 2020 году этот небольшой ресторан получил звание Best Ski Hut. По утрам гостей ждет обильный альпийский завтрак, после катания - согревающая чашка чая с панорамой Циллертальских Альп, а на ужин - изысканная трапеза из 4 или 5 блюд. Еще одно заслуживающее внимания место в этом регионе - горный ресторанчик “Кристаллхютте” (Kristalhuette) на высоте 2147 м. 
				</template>
				<template v-slot:large>
					В придачу к панораме заснеженных склонов, здесь царит богемная атмосфера<br/>с регулярными DJ-party и выставками современного искусства. </template>
				<template v-slot:image>
					<div class="content__image content__image--t5"><img src="../assets/img/t-7.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--4"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--t6" largeclass="content__large--t6" :reverse="false" class="content--t6">
				<template v-slot:title>
					завтрак под <br/>облаками
				</template>
				<template v-slot:location>
					<a href="https://g.page/Tenne-Hintertux?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_5'})">Hintertux 782, 6294 Tux, Австрия</a>
				</template>
				<template v-slot:text>
					Как насчет неторопливой и красиво сервированной трапезы прямо над заснеженными пиками? На леднике Хинтерукс в Тироле в горнолыжный сезон можно забронировать завтрак… прямо в кабине подъемника. Кабины рассчитаны на 10 пассажиров, а стол там накрывают на 4-6 персон с 9 до 10 утра. И как накрывают! Меню составит конкуренцию пятизвездочному отелю: на столе вас будут ждать охлажденное шампанское, еще теплые хлеб и выпечка, свежезаваренные чай и кофе, соки, йогурты, фермерские яйца, мюсли, местные сыры, паштеты<br/>и прочие альпийские деликатесы. Можно также заказать завтрак<br/>по-баварски: вам подадут ароматные белые мюнхенские колбаски, горячий бретель и традиционное пшеничное пиво. 
				</template>
				<template v-slot:large>
					Главное - не переборщить<br/>с последним: впереди ведь целый <br>день катания!</template>
				<template v-slot:image>
					<div class="content__image content__image--t6 content__image--h3"><img src="../assets/img/t-8.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__clouds" data-aos="fade-left" data-aos-delay="400"></div><div class="content__round content__round--5"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-green">
			<Content :smalltitle="true" infoclass="content__info--t7" largeclass="content__large--t7" class="content--t7" :reverse="true">
				<template v-slot:title>
					принцесса-<br/>фасоль
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/FgG8gbYPJhnidpAT7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_6'})">Dietzen 32, 8492 Dietzen, Австрия</a>
				</template>
				<template v-slot:text>
					Микаэла Саммер, хозяйка небольшой фермы в городке Халбенрайн в юго-восточной Штирии, любит говорить, что ей приходится растить целых четырех принцесс: ее трех дочек, и еще одну - редкий сорт фасоли под названием Runner Bean. Это стручковые бобы темно-красного или фиолетового цвета - и они действительно требуют нежного и тщательного ухода. Но дело того стоит: стручковая фасоль с ее низким содержащем жира и очень высоким - белка - незаменима в традиционной кухне Штирии. Эти бобы добавляют в салаты, супы и даже десерты, в том числе<br/>в гастрономических ресторанах. Сама Микаэла - по профессии нутрициолог. Она обожает экспериментировать с рецептами и создавать новые блюда на базе выращиваемых у себя овощей и прочих здоровых продуктов. Например, из красной фасоли она делает вкусные конфитюры - их можно попробовать и купить в магазинчике при ферме. 
				</template>
				<template v-slot:large>
					А тем, кому интересно подробнее узнать,<br/>как растет принцесса-фасоль и как за этим привередливым растением ухаживать, Микаэла с удовольствием все расскажет<br/>и поделится рекомендациями<br/>по здоровому питанию.</template>
				<template v-slot:image>
					<div class="content__image content__image--t7 content__image--h4"><img src="../assets/img/t-9.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-4" data-aos="fade-right" data-aos-delay="400"></div><div class="content__round content__round--2"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax'
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				blue: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Content,
			Burger,
			FooterBlock,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/taste'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (window.innerWidth > 1000) {
					if (procentScroll < 10) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 10) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 28) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 45) {
						this.white = true;
						this.blue = false;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 60) {
						this.white = false;
						this.blue = true;
					}
					if (procentScroll > 70) {
						this.white = true;
						this.blue = false;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 85) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 90) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_100'})
							this.scroll100 = true;
						}
					}
				} else {
					if (procentScroll < 6) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 6) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 23) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 45) {
						this.white = true;
						this.blue = false;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 58) {
						this.white = false;
						this.blue = true;
					}
					if (procentScroll > 70) {
						this.white = true;
						this.blue = false;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 82) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 90) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_taste_100'})
							this.scroll100 = true;
						}
					}
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
		}
	}
</script>

<style lang="sass">
	.intro__collage 
		z-index: 1000
		position: relative
	.bg-white
		//background-color: #fff
	.bg-blue
		//background-color: #D4E5EF
		//background-image: url(../assets/img/noise.png)
	.bg-green
		//background-color: #C9D8B6
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.collage
		&__2
			&-el-1
				position: absolute
				left: vw(-3px)
				top: 0px
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(-10px)
				span
					width: vw(301px)
					height: vw(181px)
					background-image: url(../assets/img/collage-2-el-1.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDownAlt
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(155px)
						height: vwm(68px)
			&-el-2
				position: absolute
				left: vw(-18px)
				top: vw(229px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(134px)
				span
					width: vw(529px)
					height: vw(399px)
					background-image: url(../assets/img/collage-2-el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 9s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(217px)
						height: vwm(131px)
			&-el-3
				position: absolute
				left: vw(12px)
				top: vw(96px)
				z-index: 0
				span
					width: vw(582px)
					height: vw(435px)
					background-image: url(../assets/img/collage-2-el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
			&-el-4
				position: absolute
				left: vw(765px)
				top: vw(499px)
				z-index: 8
				@media screen and (max-width: 1000px)
					left: vwm(212px)
					top: vwm(212px)
				span
					width: vw(229px)
					height: vw(165px)
					background-image: url(../assets/img/collage-2-el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(79px)
						height: vwm(54px)
			&-el-5
				position: absolute
				left: vw(941px)
				top: vw(202px)
				z-index: 6
				@media screen and (max-width: 1000px)
					top: vwm(101px)
					left: vwm(246px)
				span
					width: vw(338px)
					height: vw(426px)
					background-image: url(../assets/img/collage-2-el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(126px)
						height: vwm(158px)
			&-el-6
				position: absolute
				left: vw(-41px)
				top: vw(524px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(211px)
				span
					width: vw(190px)
					height: vw(163px)
					background-image: url(../assets/img/collage-2-el-6.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(75px)
						height: vwm(90px)
		&--2
			margin-top: vw(2px)
			margin-left: vw(-44px)
			width: vw(1328px)
			@media screen and (max-width: 1000px)
				width: vwm(551px) !important
				margin-left: vwm(-74px) !important
				margin-top: vwm(83px) !important
	.page 
		&--taste
			background-color: #C9D8B6
			transition: all 300ms
			min-height: 100vh
			&.blue
				background-color: #D4E5EF
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1093px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/bg-2.svg)
				background-repeat: no-repeat
				background-position: 50% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
	.main__block
		min-height: vw(1063px)
	.collage-mob--2
		@media screen and (max-width: 1000px)
			width: vwm(378px)
			height: vwm(237px)
			background-image: url(../assets/img/collage-2-full.png)
			background-size: contain
			margin-top: vwm(107px)
			background-repeat: no-repeat
	.main__block--taste
		@media screen and (max-width: 1000px)
			min-height: 140vw !important
		@media screen and (max-width: 1000px)
			.intro__round--2
				top: -16.90278vw
</style>