<template>
	<div class="page page--rastegaeva" :class="{white: white, blue: blue}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="0" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block">
			<div class="page__header" data-aos="fade-up">
				<HeaderBlock />
			</div>
			<Intro class="intro--7">
				<template v-slot:title>
					австрия глазами<br/>ольги растегаевой
				</template>
				<template v-slot:text>
					Тревел-журналист и фотограф, автор собственного блога<br/>о путешествиях Orange Traveler
				</template>
				<template v-slot:collage>
					<div class="collage collage--7">
						<img src="../assets/img/rastegaeva/collage-1.png" alt="">
						<div class="collage__el--7-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-6" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-7" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-8" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el--7-9" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--1 collage--mob"></div>-->
					<div class="intro__round"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--y-1">
				<template v-slot:title>
					венские <br/>выставки
				</template>
				<template v-slot:location>
					<a href="https://g.page/belvederemuseum?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_20'})">Prinz Eugen-Straße 27, 1030 Wien, Австрия</a>
				</template>
				<template v-slot:text>
					Выставки великих художников часто были поводом для покупки билета в Вену. Кураторы ведущих музеев Истории искусств, Альбертины и Альбертины Модерн, музея Леопольда и других делают из каждого проекта чуть ли не сенсацию. Только за последние годы можно было увидеть Брейгеля, Клода Моне, Тициана, Караваджо<br/>и Бернини, Яна ван Эйка, Дюрера, Модильяни. Выставка последнего и вовсе стала самой дорогой в истории Альбертины, 130 работ привезли в Вену с трех континентов! 
				</template>
				<template v-slot:large>
					в Верхнем Бельведере впервые за<br/>100 лет в столице Австрии выставили<br/>на публику «Даму с Веером»<br/>Гюстава Климта.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--hy1"><img src="../assets/img/rastegaeva/01.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--1 content__round--rs"></div></div>
				</template>
			</Content>
			<Content :short="true" :reverse="true" class="content--rs2">
				<template v-slot:text>
					Я не могла пропустить такое событие! В 2022 году тоже есть что посмотреть. Во-первых, в Верхнем Бельведере впервые за 100 лет<br/>в столице Австрии выставили на публику «Даму с Веером» Гюстава Климта. Во-вторых, в музее Леопольда решили показать редкие рисунки Эрвина Осена — любимой модели Эгона Шиле в рамках проекта «Электричество тела». Здесь же находится самая большая коллекция работ Шиле в мире. В Альбертину Модерн переедет из Пушкинского музея выставка Ксении Хауснер «Правдивая ложь», а еще будет экспозиция Ай Вэй Вэя «В поисках человечества». В самой Альбертине покажут боле 60 работ Эдварда Мунка и тех художников, кого он вдохновлял, — например, Энди Уорхола и Георга Базелица.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--2"><img src="../assets/img/rastegaeva/02.jpg" class="rellax" data-rellax-speed="0.4" data-rellax-percentage="0.5" alt=""></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :reverse="true" :smalltitle="true" infoclass="content__info--3" largeclass="content__large--3" class="content--rs3">
				<template v-slot:title class="w-130">
					деревянные маски
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/AjATaDRsBA1mQKYL9" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_21'})">5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text>
					«Кто это вообще такой?» — удивленно спросила я саму себя, впервые увидев много лет назад на рождественском рынке в Зальцбурге ряженого в шкуры предположительно человека, чье лицо было скрыто за огромной страшной деревянной маской с рогами. Тогда я впервые узнала про традицию крампусовых бегов, которую особенно чтят в Зальцбургерленде и Тироле. Это настоящий австрийский рождественский хоррор. Крампус — спутник Святого Николая, злой дух зимы, наказывающий непослушных детей и напоминающий взрослым о приходе непростого времени года. Это некое козлорогое существо, несущее хаос с помощью шума, звона цепей, криков, березовых розг, которыми он лупит мальчиков и девочек. От человеческой сущности Крампусу досталось прямохождение на двух ногах, одна из которых козлиная, с копытом. 
				</template>
				<template v-slot:large>
					Крампусовы бега устраивают в ночь <br>с 5-ого на 6-ое декабря, в день <br>Святого Николая. </template>
				<template v-slot:image>
					<div class="content__image content__image--hy2"><img src="../assets/img/rastegaeva/03.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""></div>
				</template>
			</Content>
			<Content :reverse="false" :smalltitle="true" infoclass="content__info--3" largeclass="content__large--3" class="content--rs4">
				<template v-slot:text>
					Перхта  — богиня зимы, которая приходит в обличии злой старой ведьмы, с морщинистым лицом, путаными волосами, с корзиной<br/>за спиной, полной чьих-то ног, пугающая детей. Да и взрослых тоже, чего уж там. «Berchtentag» — день Перхты в Австрии, его празднуют шествиями 6 января. В этот день она бродит со своей сворой нечисти<br/>и даже заглядывает в дома, чтобы узнать, как вели себя дети.<br/>В прошедшем году на той же главной ярмарке я увидела одного из мастеров, который вырезал из сосны очередную страшную морду. Франц Мецгер, военный полицейский из Штрасвальхена, уже много лет создает маски Крампусов и Перхт. Стоимость в среднем составляет около 300 евро, но может достигать 1500 евро. Цена зависит<br/>от сложности и от дороговизны рогов: самые дорогие — с трофеями<br/>из Африки.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--hy3"><img src="../assets/img/rastegaeva/04.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--rs2 content__round--rs"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="false" class="content--rs5" :reverse="false">
				<template v-slot:title>
					майрхофен
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/HT5FfbQ1f9uuXvAm8" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_22'})">Майрхофен 6290, Австрия</a>
				</template>
				<template v-slot:text>
					Майрхофен — самый веселый курорт в долине Циллерталь! И не зря его любят большинство горнолыжников из России. О, эта легкость бытия, несмолкаемые диалоги о горах, снеге, новых костюмах в барах, что всегда заполнены под завязку после обеда. Сюда любят приезжать большими компаниями, чтобы соревноваться друг с другом по количеству пройденных километров трасс, скорости спусков и дурашливых снимков, хохотать до упаду над какой-нибудь ерундой — как я смеялась над отпечатком козла на стене на одном из зданий где-то на вершине горы Пенкен, вкусно обедать в одной из горных хижин — мне очень понравилось в Scheekarhutte. В Майрхофене надо ставить ежедневные цели — найти часовню архитектора Марио Ботты, промчаться на одной из лучших в Европе трасс для слалома №15, спуститься по черной-пречерной «Харакири» — самой крутой трассе в Австрии с уклоном в 78°. Я не смогла, но вдруг это получится у вас?
				</template>
				<template v-slot:large>
					Майрхофен — самый веселый курорт в долине Циллерталь! </template>
				<template v-slot:image>
					<div class="content__image content__image--hy4"><img src="../assets/img/rastegaeva/05.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-rs1"></div><div class="content__round content__round--rs3 content__round--rs"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" class="content--rs6" largeclass="content__large--6" :reverse="true">
				<template v-slot:title>
					термы в фюгене
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/2drotfGXC4bg3Yo56" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_23'})">Erlebnistherme Zillertal Badweg 1, 6263 Fügen, Австрия</a>
				</template>
				<template v-slot:text>
					От воды, подсвеченной иссиня-изумрудными огнями, поднимается пар. Вода такая теплая, что я почти не чувствую свое тело, и кажется, что я в невесомости. Легкий мороз щиплет нос, на волосы падают снежинки — это один из любимых моментов. Если разменять веселье апре-ски на негу в терминальном бассейне, то еще можно успеть застать закат в горах, любоваться которым можно прямо в купальнике. Водные миры Австрии никак нельзя обходить стороной. В Циллертале главный комплекс ErlebnisTherme находится в Фюгене, куда легко добраться из любого городка в долине. В термах можно совместить негу с водным безумием — покружиться в центрифуге, скатиться с горок. Есть «лягушатники» для самых маленьких горнолыжников. Но я ратую за релакс, здесь есть все, что я люблю, — лагуны с волнами, бассейны с соленой водой, зоны для дремы, массажные и процедурные кабинеты, целый этаж с саунами всех типов влажности и температурных режимов.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--hy7"><img src="../assets/img/rastegaeva/06.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""></div>
				</template>
			</Content>
			<Content :smalltitle="true" class="content--rs7" largeclass="content__large--6">
				<template v-slot:text>
					В Австрии надо пережить абсолютно новый опыт под названием aufguss — банный групповой ритуал парения в сауне. В термах Фюгена он проводится каждый час в панорамной бане. Ауфгусс-мейстер выбирает эфирные масла, поддает пару, управляет температурой и в целом устраивает маленькое шоу для своих нагих зрителей. Вы же помните, что в Австрии сауны и бани не разрешается посещать в купальных костюмах? ErlebnisTherme открыт ежедневно с 10.00 до 20.00, бани включают к 15.00. Тарифов на билеты множество: от двухчасовых до дневных семейных.
				</template>
				<template v-slot:large>
					В Австрии надо пережить абсолютно новый опыт под названием aufguss <br>— банный групповой ритуал парения <br>в сауне.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--hy7"><img src="../assets/img/rastegaeva/07.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--2 content__round--rs"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="false" infoclass="content__info--7" largeclass="content__large--7" class="content--y-8">
				<template v-slot:title>
					циллерталь арена
				</template>
				<template v-slot:location>
					<a href="https://g.page/myzillertalarena?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_24'})">Rohr 23, 6280 Rohrberg, Австрия</a>
				</template>
				<template v-slot:text>
					Кто-то из местных, в отеле или баре, обязательно спросит: «А где вы сегодня катались?» И если ты ответишь, что в Циллерталь Арене, обязательно услышишь в ответ, что это их самая любимая зона катания из четырех. Циллерталь Арена — самая большая горнолыжная зона в долине, простирающаяся до Пинцгау. Много трасс проложено на высоте 2500 метров, а это дает почти стопроцентную гарантию снега. И каких трасс! Есть где скатиться и проверить скорость, где снять собственное лыжное видео, отточить карвинг, получить порцию адреналина и восторга от открывающихся панорам — ведь кругом сплошь стена из трехтысячников! 
				</template>
				<template v-slot:large>
					Мой совет — хотя бы один из дней посвятить ски-сафари под <br>названием «Арена-тур».
				</template>
				<template v-slot:image>
					<div class="content__image"><img src="../assets/img/rastegaeva/08.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-rs2"></div></div>
				</template>
			</Content>
			<Content :smalltitle="false" :reverse="true" class="content--y-9">
				<template v-slot:text>
					Он стартует от верхней точки подъемника Karspitzbahn из Целль-ам-Циллера и собирает лучшие трассы, фототочки, где можно сделать селфи с желтым слоненком — символом курорта, и панорамные площадки в один увлекательный дневной маршрут. Квинтэссенция «Арена-тура» — один из самых длинных спусков в Австрии Hohenfressertour «Пожиратели высот» с перепадом в 1930 метров! Если пройти 6 из 13 станций тура по Arena Champions, разбросанных по лыжной зоне, можно получить в подарок личный цифровой фотоальбом! Главное, зарегистрироваться на сайте www.skiline.cc/zillertalarena и не забывать прикладывать ски-пасс к автоматическим фотокамерам! Ну а гастропаузы на горе, чего уж таить, одно из любимых времяпрепровождений. Горных хижин, где на высоте подают блюда высокой кухни, в долине много. Самая последняя рекомендация — новый ресторан Wiesenalm. 
				</template>
				<template v-slot:large>
					Главное, разомлев от видов, вкуснейших блюд и солнечных ванн, собраться с силами и снова умчаться в белую снежную даль!
				</template>
				<template v-slot:image>
					<div class="content__image"><img src="../assets/img/rastegaeva/09.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--5 content__round--rs"></div><div class="content__photo-rs3"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :reverse="true" :smalltitle="true" infoclass="content__info--3" largeclass="content__large--3" class="content--y-3 content--rs10">
				<template v-slot:title>
					ледник хинтертукс
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/3U7G3DGhN1eFmQew5" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_25'})">Хинтертукс 6294, Австрия</a>
				</template>
				<template v-slot:text>
					Это не была ни я, ни один из тысяч лыжников, что катались на леднике Хинтертукс круглый год. Свой секрет ледник открыл только Роману Эрлеру, местному жителю и гиду, который в 2007 году заинтересовался открывшейся широкой трещиной. Мне повезло познакомиться с первооткрывателем лично и вместе с ним исследовать систему ледяных пещер изнутри. <div class="serpator"></div> Леднику предположительно 10 000 лет, он не самый большой в Европе, но состоит из 190 миллионов кубометров льда, и в некоторых точках его глубина составляет 120 метров! Залы, которые открыты для посещений, находятся примерно на глубине 20 метров прямо под трассой №5. Я была совершенно очарована настоящей естественной красотой «Ледяного дворца». 
				</template>
				<template v-slot:large>
					Представлялось, что сейчас я дотронусь до стены льда, возраст которой — несколько сотен лет, и произойдет какое-то чудо!</template>
				<template v-slot:image>
					<div class="content__image content__image--hy2"><img src="../assets/img/rastegaeva/10.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__photo-rs4"></div></div>
				</template>
			</Content>
			<Content :reverse="false" :smalltitle="true" infoclass="content__info--3" largeclass="content__large--3" class="content--y-4 content--y-4-1">
				<template v-slot:text>
					Внутри абсолютно ничего не тронули, не приукрасили. Лишь протянули электричество для подсветки. Несколько залов на разных уровнях с ледяными аллеями, скульптурами, которые создала сама природа, выглядят фантастически! Есть даже ледяное озеро, образовавшееся из-за талой воды. В нем ежедневно совершают заплывы моржи, иногда тренируются дайверы, а все желающие касаются на лодочке! Такого нет больше нигде в мире! В весенние месяцы из-за разницы температур внутри ледника можно найти ледяные кристаллы — снежинки размером с ладонь, которые образовываются на потолках пещер и даже глубоко под водой! Роман мне показывал снимки, как держит в руках одну из таких. В Natur Eis Palast работают ученые, они ведут замеры и делают множество наблюдений. Если и вам доведется прогуляться с Романом по ледяным коридорам, попросите его рассказать про последние находки и открытия в гляциологии. Чтобы попасть в ледяной дворец, нужно подняться на самый верх и пройти. Стоимость билета для взрослого: 26 евро.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--hy3"><img src="../assets/img/rastegaeva/11.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""></div><div class="content__round content__round--rs6 content__round--rs"></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax';
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				blue: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Burger,
			Content,
			FooterBlock,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/rastegaeva'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				console.log(val)
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				console.log(procentScroll)
				if (window.innerWidth > 1000) {
					if (procentScroll < 10) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 10) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 23) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_rastegaeva_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 38) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 48) {
						this.white = false;
						this.blue = true;
					}
					if (procentScroll > 62) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 81) {
						this.white = false;
						this.blue = false;
					}
				} else {
					if (procentScroll < 3) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 3) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 18) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_rastegaeva_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 35) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 45) {
						this.white = false;
						this.blue = true;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_rastegaeva_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 61) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 80) {
						this.white = false;
						this.blue = false;
					}
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
		}
	}
</script>

<style lang="sass">
	.content--rs6
		padding-top: vw(248px)
		.content__title--small
			margin-top: vw(32px)
		@media screen and (max-width: 1000px)
			padding-bottom: 0
	.content__photo-rs1
		background-image: url(../assets/img/rs-el-1.png)
		width: vw(441px)
		height: vw(214px)
		position: absolute
		background-size: contain
		background-repeat: no-repeat
		bottom: vw(-36px)
		right: vw(-75px)
		@media screen and (max-width: 1000px)
			width: vwm(233px)
			height: vwm(157px)
			bottom: vwm(-52px)
			right: vwm(-7px)
	.content__photo-rs2
		background-image: url(../assets/img/rs-el-2.png)
		width: vw(217px)
		height: vw(256px)
		position: absolute
		background-size: contain
		background-repeat: no-repeat
		bottom: vw(-9px)
		right: vw(-89px)
		@media screen and (max-width: 1000px)
			width: vwm(153px)
			height: vwm(171px)
			bottom: vwm(-129px)
			right: vwm(-10px)
	.content__photo-rs3
		background-image: url(../assets/img/rs-el-3.png)
		width: vw(250px)
		height: vw(270px)
		position: absolute
		background-size: contain
		background-repeat: no-repeat
		bottom: vw(-203px)
		right: vw(-68px)
		@media screen and (max-width: 1000px)
			width: vwm(156px)
			height: vwm(152px)
			right: vwm(-19px)
			bottom: vwm(-58px)
	.content__photo-rs4
		background-image: url(../assets/img/rs-el-4.png)
		width: vw(252px)
		height: vw(330px)
		position: absolute
		background-size: contain
		background-repeat: no-repeat
		bottom: vw(-124px)
		right: vw(-34px)
		@media screen and (max-width: 1000px)
			width: vwm(156px)
			height: vwm(196px)
			right: vwm(-10px)
			bottom: vwm(-46px)
	.content__round--rs2
		top: vw(-83px)
		left: vw(-77px)
	.content__round--rs3
		left: vw(-76px)
		top: vw(-85px)
	.content__round--rs6
		left: vw(-77px)
		top: vw(-88px)
	.content__round--rs
		background-image: url(../assets/img/round-rs.svg)
		width: vw(175px)
		height: vw(175px)
	.content--rs3
		.content__title--small
			letter-spacing: vw(4.2px)
			white-space: nowrap
			@media screen and (max-width: 1000px)
				white-space: normal
		@media screen and (max-width: 1000px)
			padding-top: 0 
			padding-bottom: vwm(20px)
	.content--rs2
		@media screen and (max-width: 1000px)
			padding-bottom: vwm(20px)
	.content--rs4
		@media screen and (max-width: 1000px)
			padding-bottom: vwm(20px)
			padding-top: vwm(0px)
	.content--rs7
		padding-top: vw(42px)
		@media screen and (max-width: 1000px)
			padding-bottom: 0
	.content--rs10
		padding-top: vw(362px)
	.content--rs5
		@media screen and (max-width: 1000px)
			padding-top: 0
			padding-bottom: 0
	.collage
		&--7
			margin-top: vw(97px)
			margin-left: vw(12px)
			width: vw(1225px)
			@media screen and (max-width: 1000px)
				width: vwm(377px)
				margin-top: vwm(60px)
				margin-left: vwm(-1px)
			&+.intro__round
				@media screen and (max-width: 1000px)
					top: -13vw !important
					left: -4vw
					&:after
						display: none
		img
			width: 100%
			z-index: 3
			position: relative
		&__el--7
			&-1
				position: absolute
				left: vw(-12px)
				top: vw(-123px)
				z-index: 4
				@media screen and (max-width: 1000px)
					right: vwm(-6px)
					top: vwm(-70px)
				span
					width: vw(220px)
					height: vw(260px)
					background-image: url(../assets/img/rastegaeva/collage-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(110px)
			&-2
				position: absolute
				left: vw(-29px)
				top: vw(179px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(10px)
					top: vwm(69px)
				span
					width: vw(192px)
					height: vw(226px)
					background-image: url(../assets/img/rastegaeva/collage-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(96px)
			&-3
				position: absolute
				left: vw(-79px)
				top: vw(339px)
				z-index: 4
				@media screen and (max-width: 1000px)
					right: vwm(-6px)
					top: vwm(108px)
				span
					width: vw(389px)
					height: vw(261px)
					background-image: url(../assets/img/rastegaeva/collage-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					//animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(176px)
						height: vwm(136px)
			&-4
				position: absolute
				left: vw(187px)
				top: vw(323px)
				z-index: 3
				@media screen and (max-width: 1000px)
					left: vwm(81px)
					top: vwm(74px)
				span
					width: vw(199px)
					height: vw(217px)
					background-image: url(../assets/img/rastegaeva/collage-5-1.png)
					background-size: contain
					background-repeat: no-repeat
					transform: rotate(-10deg)
					display: block
					//animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(110px)
			&-5
				position: absolute
				right: vw(-14px)
				top: vw(-32px)
				z-index: 3
				@media screen and (max-width: 1000px)
					right: vwm(16px)
					top: vwm(-27px)
				span
					width: vw(262px)
					height: vw(155px)
					background-image: url(../assets/img/rastegaeva/collage-6.png)
					background-size: contain
					background-repeat: no-repeat
					transform: rotate(-3deg)
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(110px)
			&-6
				position: absolute
				right: vw(-22px)
				top: vw(88px)
				z-index: 3
				@media screen and (max-width: 1000px)
					right: vwm(-25px)
					top: vwm(-3px)
				span
					width: vw(184px)
					height: vw(241px)
					background-image: url(../assets/img/rastegaeva/collage-7.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					//animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(110px)
			&-7
				position: absolute
				right: vw(-78px)
				top: vw(298px)
				z-index: 3
				@media screen and (max-width: 1000px)
					right: vwm(-11px)
					top: vwm(85px)
				span
					width: vw(299px)
					height: vw(251px)
					background-image: url(../assets/img/rastegaeva/collage-8.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: simpleZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(135px)
						height: vwm(132px)
			&-8
				position: absolute
				right: vw(152px)
				top: vw(253px)
				z-index: 3
				@media screen and (max-width: 1000px)
					right: vwm(24px)
					top: vwm(34px)
				span
					width: vw(167px)
					height: vw(291px)
					background-image: url(../assets/img/rastegaeva/collage-9.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(134px)
						height: vwm(145px)
			&-9
				position: absolute
				left: 0
				right: 0
				margin: auto
				top: vw(-53px)
				display: flex
				align-items: center
				justify-content: center
				z-index: 3
				@media screen and (max-width: 1000px)
					right: vwm(21px)
					top: vwm(-38px)
				span
					width: vw(184px)
					height: vw(157px)
					background-image: url(../assets/img/rastegaeva/collage-10.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					//animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(110px)
	
	.w-130
		width: 130%
	.page
		&--rastegaeva
			background-color: #D3E3EC
			min-height: 100vh
			transition: all 300ms
			.main__block
				@media screen and (max-width: 1000px)
					min-height: 168vw !important
			&.blue
				background-color: #EBD5C2
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1101px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0.5vw
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/rastegaeva/map.svg)
				background-repeat: no-repeat
				background-position: 50% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
			.content--y-9 .content__image
				@media screen and (max-width: 1000px)
					height: auto !important
</style>