<template>
	<div class="quote">
		<div class="quote__line" data-aos="fade-right" data-aos-delay="500"></div>
		<div class="container">
			<div class="quote__box">
				<div class="quote__main">
					<div class="quote__main-content">
						<div class="quote__main-portrait"><slot name="portrait"></slot></div>
						<div class="quote__main-element-1"><slot name="element-1"></slot></div>
						<div class="quote__main-element-2"><slot name="element-2"></slot></div>
					</div>
					<div class="quote__main-title"><slot name="title"></slot></div>
				</div>
				<div class="quote__info">
					<div class="quote__info-text"><slot name="text"></slot></div>
					<div class="quote__info-button"><slot name="button"></slot></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Quote',
	}
</script>

<style lang="sass">
	.quote
		position: relative
		padding-bottom: vw(101px)
		&__line
			width: vw(1543px)
			height: vw(408px)
			background-size: cover
			background-repeat: no-repeat
			position: absolute
			background-image: url(../assets/img/line.svg)
			top: vw(-75px)
			left: vw(-5px)
			@media screen and (max-width: 1000px)
				width: vwm(1142px)
				height: vwm(303px)
				left: vwm(-33px)
		&__box
			display: flex
			justify-content: space-between
			@media screen and (max-width: 1000px)
				flex-direction: column
		&__main
			&-title
				font-size: vw(26px)
				line-height: vw(34px)
				color: #C20E1A
				letter-spacing: 1.8px
				font-family: 'PosteRetro'
				@media screen and (max-width: 1000px)
					font-size: vwm(26px)
					letter-spacing: 1.3px
			&-content
				position: relative
			&-portrait
				img
					width: 100%
		&__info
			&-text
				font-size: vw(20px)
				line-height: vw(27px)
				color: #000
				text-transform: uppercase
				letter-spacing: 1.8px
				font-family: 'Futura Bk BT'
				position: relative
				@media screen and (max-width: 1000px)
					font-size: vwm(20px) !important
					line-height: vwm(26px) !important
					width: vwm(297px) !important
					letter-spacing: -0.4px
				&:before
					content: ''
					background-image: url(../assets/img/quotes.svg)
					position: absolute
					width: vw(28px)
					height: vw(21px)
					background-size: contain
					background-repeat: no-repeat
					@media screen and (max-width: 1000px)
						width: vwm(30px)
						height: vwm(23px)
			&-button
				margin-top: vw(28px)
				position: relative
				z-index: 10
				@media screen and (max-width: 1000px)
					margin-top: vwm(40px)
					padding-left: vwm(7px)
				a
					font-size: vw(18px)
					font-family: 'Futura Bk BT'
					text-decoration: none
					color: #BD242B
					display: flex
					align-items: center
					justify-content: flex-end
					transition: all 300ms
					@media screen and (max-width: 1000px)
						font-size: vwm(24px)
						justify-content: space-between
					&:after
						content: ''
						background-image: url(../assets/img/arrow.svg)
						width: vw(85px)
						height: vw(11px)
						background-size: contain
						background-repeat: no-repeat
						display: block
						margin-left: vw(9px)
						margin-top: vw(4px)
						@media screen and (max-width: 1000px)
							width: vwm(176px)
							height: vwm(21px)
							margin-top: vwm(2px)
					&:hover
						margin-right: vw(-10px)
						@media screen and (max-width: 1000px)
							margin-right: 0
</style>