<template>
	<div class="intro">
		<div class="intro__title" data-aos="fade-up" data-aos-delay="800"><slot name="title"></slot></div>
		<div class="intro__text" data-aos="fade-up" data-aos-delay="1000"><slot name="text"></slot></div>
		<div class="intro__collage" data-aos="fade-up" data-aos-delay="1200"><slot name="collage"></slot></div>
	</div>
</template>

<script>
	export default {
		name: 'Intro',
	}
</script>

<style lang="sass">
	.intro
		padding-bottom: vw(58px)
		&--2
			padding-bottom: vw(33px)
		&--6
			.intro__round
				left: 0
				right: 0
				margin: auto
				top: vw(68px)
			.intro__text
				margin-top: vw(10px)
				@media screen and (max-width: 1000px)
					margin-top: 9vw
			.intro__title
				font-size: vw(65px)
				letter-spacing: 0.45vw
				line-height: vw(84px)
				margin-top: vw(58px)
				@media screen and (max-width: 1000px)
					font-size: 9vw
					line-height: 1.2
					margin-top: 40vw
		&--7
			.intro__round
				left: 0
				right: 0
				margin: auto
				top: -5.77778vw
			.intro__text
				margin-top: vw(10px)
				@media screen and (max-width: 1000px)
					margin-top: 9vw
			.intro__title
				font-size: vw(65px)
				letter-spacing: 0.45vw
				line-height: vw(84px)
				margin-top: vw(58px)
				@media screen and (max-width: 1000px)
					font-size: 9vw
					line-height: 1.2
					margin-top: 40vw
		&__title
			text-align: center
			font-size: vw(90px)
			color: #C20E1A
			font-family: 'PosteRetro'
			margin-top: vw(66px)
			padding-left: vw(10px)
			letter-spacing: 0.07em
			@media screen and (max-width: 1000px)
				font-size: vwm(40px)
				margin-top: vwm(123px)
				letter-spacing: 1.1vw
				padding-left: 0
		&__text
			width: vw(634px)
			text-align: center
			margin: auto
			margin-top: vw(30px)
			color: #000
			font-size: vw(17px)
			font-family: 'Futura Bk BT'
			font-size: vw(17px)
			line-height: vw(22.95px)
			@media screen and (max-width: 1000px)
				font-size: vwm(18px)
				margin-top: vwm(20px)
				letter-spacing: 0vw
				line-height: vwm(22.3px)
				padding-left: 0
				width: vwm(332px)
				br
					display: none
		&__round
			width: vw(204px)
			height: vw(204px)
			background-image: url(../assets/img/round-text.png)
			background-repeat: no-repeat
			background-size: contain
			position: absolute
			right: vw(-22px)
			top: vw(-42px)
			z-index: 5
			animation: rotateRound
			animation-duration: 10s
			animation-timing-function: linear
			animation-iteration-count: infinite
			@media screen and (max-width: 1000px)
				width: vwm(114px)
				height: vwm(114px)
				right: vwm(73px)
				top: vwm(-52px)
				background: none
				animation: none
				&:before
					content: ''
					display: block
					width: 100%
					height: 100%
					background-image: url(../assets/img/round-text.png)
					background-repeat: no-repeat
					background-size: contain
					position: absolute
					animation: rotateRound
					animation-duration: 10s
					animation-timing-function: linear
					animation-iteration-count: infinite
				&:after
					content: ''
					display: block
					width: 100%
					height: 100%
					background-size: vwm(13px)
					background-position: 50% 50%
					background-repeat: no-repeat
					background-image: url(../assets/img/down.svg)
					position: absolute
					top: 0
					left: 0
			&--2
				top: vw(59px)
				right: vw(386px)
			&--3
				top: vw(-21px)
				right: vw(55px)

			&--4
				top: vw(6px)
				right: vw(308px)
</style>