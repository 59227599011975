<template>
	<div class="page page--telnov" :class="{white: white, blue: blue}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="0" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block main__block--telnov">
			<div class="page__header">
				<HeaderBlock />
			</div>
			<Intro class="intro--9">
				<template v-slot:title>
					австрия глазами <br>алекса дубаса
				</template>
				<template v-slot:text>
					Ведущий собственного шоу «Ленивое воскресенье» на радио «Серебряный дождь» и программы «Наблюдатель» на телеканале «Культура», автор множества книг и спектаклей.
				</template>
				<template v-slot:collage>
					<div class="collage collage--9">
						<img src="../assets/img/collage-9.png" alt="">
						<div class="collage__9-el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-6" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-7" data-aos="fade-up" data-aos-delay="2000" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-8" data-aos="fade-up" data-aos-delay="2200" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-9" data-aos="fade-up" data-aos-delay="2200" data-aos-offset="-1000"><span></span></div>
				<div class="collage__9-el-10" data-aos="fade-up" data-aos-delay="2200" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--2 collage--mob"></div>-->
					<div class="intro__round intro__round--9"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content class="content--d1" :reverse="true">
				<template v-slot:title>
					«ангар 7»
				</template>
				<template v-slot:location>
					<a href="https://g.page/hangar-7-merchandising-shop?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_32'})">Wilhelm-Spazier-Straße 7a, 5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text class="content__info--d1">
					В пригороде Зальцбурга есть машина времени. Она и выглядит так, как мы себе ее представляем: огромный футуристический ангар из стали и стекол. Как только ты входишь в него — оказываешься в удивительном мире. Как будто ты в будущем, в которое почему-то вернулась мода прекрасных 60-х, когда самолеты, машины, мотороллеры и бытовая техника были блестящими и с покатыми боками. Такими, что сразу же возникает желание что-то потрогать, повертеть, прокатиться. <br><br>
					И то, что мы видим в «Ангаре 7», похоже на коллекцию мальчишки, собирающего машинки, вертолетики и самолетики. Только этот 77-летний мальчишка — с седыми волосами, и собирает он самолеты, вертолеты и автомобили вполне себе всамделишные. И для своих сокровищ построил этот огромный ангар.
				</template>
				<template v-slot:large>
					В пригороде Зальцбурга есть машина времени. Она и выглядит так, как мы себе ее представляем: огромный футуристический ангар из стали и стекол.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--d1"><div class="plane_1-wrap" data-aos="fade-right"><div class="plane_1"></div></div><img src="../assets/img/dubas/p_1.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--db content__round--tl1"></div></div>
				</template>
			</Content>
			<Content class="content--d2">
				<template v-slot:text>
					Имя коллекционера — Дитрих Матешиц, он основатель концерна Red Bull и самый богатый человек Австрии. Его история успеха удивляет и вдохновляет. Его биография из тех, о которых Netflix снимает фильмы. О том, как следовать своей мечте, о том, как в 50 лет можно с нуля начать новый бизнес, верить в него и стать миллиардером. Сейчас этих миллиардов примерно двенадцать. Впрочем, не буду пересказывать статью из Википедии о Дитрихе Матешице. Это отдельный и увлекательный рассказ.
					<br>
					<br>
					Одержимому коллекционеру важны зрители, то есть мы. Поэтому Дитрих приглашает в свой музей всех — очароваться и разделить с ним страсть.
					<br>
					<br>
					А чтобы мы задержались здесь подольше, он открыл ресторан Ikarus, куда раз в сезон приглашает на гастроли знаменитых шеф-поваров и миксологов.
					<br>
					<br>
					Это не реклама, я вот своему сыну не разрешаю пить «энергетики», здесь про другое: про мечту. Сидишь смотришь на всю эту красоту и получаешь самое главное, что в принципе ты можешь получить в путешествии, — эмоции. И эти эмоции — сильные и созидательные, будят в тебе ребенка.
					<br>
					<br>
					Все самолеты в «Ангаре 7» заправлены и готовы к полету.

				</template>
				<template v-slot:image>
					<div class="content__image content__image--d2"><img src="../assets/img/dubas/p_2.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__el-d2" data-aos="fade-right"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content class="content content--d3" :smalltitle="false" :largetitle="true" infoclass="content__info--r2" largeclass="content__large--t2" :reverse="true">
				<template v-slot:title>
					«моцарткугель»
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/r9cZWXsH9rTi9apg7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_33'})">Brodgasse 13, 5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text>
					Если в городе Моцарта вы попадете под «зальцбургские нитки» (так местные называют дождь), то вот вам идея, как азартно и увлекательно переждать непогоду.
					<br>
					<br>
					Устройте баттл «моцарткугелей»! Поверьте, это будет яркое и вкусное приключение.
					<br>
					<br>
					«Моцарткугель» — «моцартовский шарик», это традиционная зальцбургская конфета, прекрасно известная нам: шоколад с марципановой начинкой в золотой фольге, украшенной портретом великого композитора. Профиль Вольфганга Амадея в белом парике обещает нам и как бы сам предвкушает наслаждение десертом, изысканным, как его сонаты.
					<br>
					<br>
					Одно «но». То, что продается в наших магазинах, — лишь один из десятков видов этих конфет, причем не факт, что самый интересный!
					<br>
					<br>
					Настоящий «моцарткугель» придумал в 1890 году Пауль Фюрст. Их в его кондитерской и по сей день делают так: из фисташкового марципана в оболочке из нуги вручную формируют шарик. Затем его насаживают на деревянную палочку, чтобы обмакнуть в темную шоколадную глазурь.
				</template>
				<template v-slot:large>
					«моцарткугели», сделанные традиционным способом, можно определить по шоколадному хвостику от палочки.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--tl3"><img src="../assets/img/dubas/p_3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="content__round content__round--db content__round--2"></div></div>
				</template>
			</Content>
			<Content class="content content--tl4" :smalltitle="false" :largetitle="true" infoclass="content__info--r2" largeclass="content__large--t2">
				<template v-slot:text>
					После того, как конфета остынет и затвердеет, палочка вынимается, а оставшаяся дырочка так же заполняется шоколадом. Собственно, «моцарткугели», сделанные традиционным способом, то есть вручную разными производителями, можно определить по этому шоколадному хвостику от палочки. Конвейерное производство, хотя и предполагает 14 приемов и занимает два часа, не оставляет таких милых «дефектов».
					<br>
					<br>
					Пауль Фюрст был хорошим кондитером, но не ушлым бизнесменом, поэтому не запатентовал свое изобретение. Популярные конфеты теперь растиражированы другими компаниями, и их производство поставлено на поток. Что вовсе не плохо, ведь все эти шарики по-своему вкусные, а у нас с вами есть возможность эти вкусы сравнить.
					<br>
					<br>
					<p class="red">
						Алекс Дубас принял участие в батле Моцаркугелей.
						<br>
						Кто победил? Смотрите видео в профиле <a href="javascript:void(0)" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5933698721&iu=/81006599/hmiru-mc/count')">@visitaustria_ru</a>
					</p>
				</template>
				<template v-slot:image>
					<div class="content__image content__image--tl4"><img src="../assets/img/dubas/p_4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5"  alt=""><div class="el_mor" data-aos="fade-up"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax'
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				blue: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Content,
			Burger,
			FooterBlock,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/dubas'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				console.log(procentScroll)
				if (window.innerWidth > 1000) {
					if (procentScroll < 10) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 10) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 28) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_dubas_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 45) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 60) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 76) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 85) {
						this.white = false;
						this.blue = false;
					}
				} else {
					if (procentScroll > 3) {
						this.white = true;
						this.blue = false;
					} else {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 22) {
						this.white = false;
						this.blue = false;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_dubas_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 39) {
						this.white = true;
						this.blue = false;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_dubas_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 55) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 73) {
						this.white = true;
						this.blue = false;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_dubas_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 84) {
						this.white = false;
						this.blue = false;
					}
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
			openLink(link) {
				window.open(link, '_blank').focus();
				this.$gtag.event('click', {event_category: 'link','event_label': 'link_dubas'})
			}
		}
	}
</script>

<style lang="sass">
	.red
		color: #BD242B
		a
			color: #BD242B
			font-weight: bold
	.main__block--telnov
		@media screen and (max-width: 1000px)
			min-height: vwm(608px) !important
	.content__photo-tl1
		background-image: url(../assets/img/tl-3.png)
		width: vw(180px)
		height: vw(430px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		top: vw(-195px)
		right: vw(-56px)
		@media screen and (max-width: 1000px)
			width: vwm(100px)
			height: vwm(184px)
			right: vwm(-33px)
			top: vwm(-34px)
	.content__photo-tl2
		background-image: url(../assets/img/tl-5.png)
		width: vw(219px)
		height: vw(264px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		bottom: vw(-56px)
		left: vw(-76px)
		@media screen and (max-width: 1000px)
			width: vwm(200px)
			height: vwm(159px)
			left: vwm(-25px)
			bottom: vwm(-23px)
	.content__photo-tl3
		background-image: url(../assets/img/tl-9.png)
		width: vw(407px)
		height: vw(176px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		bottom: vw(-56px)
		left: vw(-76px)
		@media screen and (max-width: 1000px)
			width: vwm(218px)
			height: vwm(150px)
			left: vwm(7px)
			bottom: vw(-139px)
	.content__photo-tl4
		background-image: url(../assets/img/tl-12.png)
		width: vw(199px)
		height: vw(271px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		bottom: vw(-26px)
		left: vw(-76px)
		@media screen and (max-width: 1000px)
			width: vwm(127px)
			height: vwm(226px)
			left: vwm(-1px)
			bottom: vwm(-49px)
	.content__photo-tl5
		background-image: url(../assets/img/tl-15.png)
		width: vw(348px)
		height: vw(201px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		bottom: vw(-126px)
		left: vw(-48px)
		@media screen and (max-width: 1000px)
			width: vwm(215px)
			height: vwm(126px)
			left: vwm(10px)
			bottom: vwm(-47px)
	.content__round--tl1
		left: vw(420px)
		bottom: vw(-86px)
	.content__round--tl2
		left: vw(-90px)
		top: vw(-90px)
	.content__round--tl3
		left: vw(394px)
		bottom: vw(-100px)
	.content__round--tl4
		left: vw(394px)
		bottom: vw(-100px)
	.content__round--tl5
		left: vw(-87px)
		top: vw(-87px)
	.content__round--tl6
		left: vw(401px)
		top: vw(-87px)
	.content--tl1
		padding-top: vw(191px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(20px)
			padding-bottom: vwm(20px)
		.content__title
			margin-top: vw(-21px)
		.content__text
			letter-spacing: -0.8px
	.content--tl2
		padding-top: vw(85px)
		@media screen and (max-width: 1000px)
			padding-bottom: vwm(20px)
		.content__title
			margin-top: vw(-21px)
		.content__text
			letter-spacing: -0.8px
			margin-top: vw(10px)
	.content--tl3
		padding-top: vw(240px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(0px)
			padding-bottom: vwm(30px)
		.content__title
			margin-top: vw(-2px)
		.content__text
			letter-spacing: -0.8px
	.content--tl4
		padding-top: vw(62px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(10px)
			padding-bottom: vwm(10px)
		.content__title
			margin-top: vw(-2px)
		.content__text
			letter-spacing: -0.8px
			margin-top: vw(23px)
	.content--tl5
		padding-top: vw(270px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(10px)
			padding-bottom: vwm(20px)
		.content__title
			margin-top: vw(-2px)
		.content__text
			letter-spacing: -0.8px
	.content--tl6
		padding-top: vw(76px)
		@media screen and (max-width: 1000px)
			padding-bottom: vwm(20px)
		.content__title
			margin-top: vw(-2px)
		.content__text
			letter-spacing: -0.8px
			margin-top: vw(16px)
	.content--tl7
		padding-top: vw(76px)
		padding-bottom: vw(149px)
		.content__title
			margin-top: vw(-2px)
		.content__text
			letter-spacing: -0.8px
			margin-top: vw(16px)
	.content__image--tl1
		width: vw(508px)
		height: vw(638px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
			margin-top: vwm(20px)
	.content__image--tl2
		width: vw(546px)
		height: vw(393px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl3
		width: vw(490px)
		height: vw(500px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl4
		width: vw(585px)
		height: vw(421px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl5
		width: vw(490px)
		height: vw(515px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl6
		width: vw(546px)
		height: vw(393px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl7
		width: vw(490px)
		height: vw(580px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl8
		width: vw(576px)
		height: vw(415px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl9
		width: vw(589px)
		height: vw(675px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__image--tl10
		width: vw(490px)
		height: vw(626px)
		@media screen and (max-width: 1000px)
			width: 100%
			height: auto !important
	.content__round--tl
		background-image: url(../assets/img/round-8.svg)
		width: vw(176px)
		height: vw(176px)
	.intro__round--9
		@media screen and (max-width: 1000px)
			left: vwm(116px) !important
	.intro--9
		margin-top: vw(-11px)
		.intro__title
			font-size: vw(65px)
			line-height: vw(84px)
			letter-spacing: vw(6.4px)
			@media screen and (max-width: 1000px)
				font-size: vwm(34px)
				line-height: vwm(44px)
		.intro__text
			margin-top: vw(11px)
			width: 43%
		.intro__round--9
			right: vw(518px)
			top: vw(-5px)
			@media screen and (max-width: 1000px)
				top: vwm(-42px)
				right: vwm(149px)
	.intro__collage 
		z-index: 1000
		position: relative
	.bg-white
		//background-color: #fff
	.bg-blue
		//background-color: #D4E5EF
		//background-image: url(../assets/img/noise.png)
	.bg-green
		//background-color: #C9D8B6
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.collage
		&--9
			margin-top: vw(21px)
			img
				width: vw(1204px)
				margin-top: vw(20px)
				margin-left: vw(20px)
				transform: translateY(-1.3vw)
				@media screen and (max-width: 1000px)
					width: 100%
					transform: none
					margin-top: vwm(40px)
		&__9
			&-el-1
				position: absolute
				left: vw(-65px)
				top: vw(166px)
				z-index: 4
				@media screen and (max-width: 1000px)
					left: vwm(-65px)
					top: vwm(46px)
				span
					width: vw(330px)
					height: vw(230px)
					background-image: url(../assets/img/dubas/el-1.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 5s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(177px)
						height: vwm(109px)
			&-el-2
				position: absolute
				left: vw(-40px)
				top: vw(355px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(124px)
					left: vwm(1px)
				span
					width: vw(249px)
					height: vw(303px)
					background-image: url(../assets/img/dubas/el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 9s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(220px)
						height: vwm(116px)
			&-el-3
				position: absolute
				left: vw(152px)
				bottom: vw(17px)
				z-index: 5
				@media screen and (max-width: 1000px)
					left: vwm(66px)
					bottom: vwm(6px)
				span
					width: vw(179px)
					height: vw(256px)
					background-image: url(../assets/img/dubas/el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(126px)
						height: vwm(126px)
			&-el-4
				position: absolute
				left: vw(157px)
				top: vw(300px)
				z-index: 6
				@media screen and (max-width: 1000px)
					left: vwm(56px)
					top: vwm(115px)
					z-index: 3
				span
					width: vw(172px)
					height: vw(144px)
					background-image: url(../assets/img/dubas/el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(92px)
						height: vwm(99px)
			&-el-5
				position: absolute
				right: vw(0px)
				top: vw(146px)
				z-index: 8
				@media screen and (max-width: 1000px)
					top: vwm(48px)
					left: vwm(275px)
				span
					width: vw(193px)
					height: vw(245px)
					background-size: contain
					background-repeat: no-repeat
					background-image: url(../assets/img/dubas/el-6.png)
					display: block
					animation: upDown
					animation-duration: 5s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(100px)
						height: vwm(123px)
			&-el-6
				position: absolute
				right: vw(1px)
				top: vw(-75px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(-15px)
					right: vwm(0px)
				span
					width: vw(320px)
					height: vw(203px)
					background-image: url(../assets/img/dubas/el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDownAlt
					animation-duration: 5s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(110px)
						height: vwm(104px)
			&-el-7
				position: absolute
				right: vw(523px)
				top: vw(21px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(15px)
					right: vwm(166px)
				span
					width: vw(165px)
					height: vw(158px)
					background-image: url(../assets/img/dubas/el-10.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(67px)
						height: vwm(80px)
			&-el-8
				position: absolute
				right: vw(155px)
				top: vw(263px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(110px)
					right: vwm(61px)
					z-index: 9
				span
					width: vw(241px)
					height: vw(304px)
					background-image: url(../assets/img/dubas/el-7.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: leftRight
					animation-duration: 5s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(75px)
						height: vwm(90px)
			&-el-9
				position: absolute
				right: vw(-32px)
				top: vw(347px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(140px)
					right: vwm(1px)
				span
					width: vw(350px)
					height: vw(283px)
					background-image: url(../assets/img/dubas/el-8.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					@media screen and (max-width: 1000px)
						width: vwm(125px)
						height: vwm(110px)
			&-el-10
				position: absolute
				right: vw(69px)
				top: vw(335px)
				z-index: 10
				@media screen and (max-width: 1000px)
					top: vwm(-10px)
					right: vwm(141px)
					display: none
				span
					width: vw(106px)
					height: vw(77px)
					background-image: url(../assets/img/dubas/el-9.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDownAlt
					animation-duration: 5s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(75px)
						height: vwm(90px)
		&--8
			margin-top: vw(-23px)
			margin-left: vw(31px)
			width: vw(1195px)
			@media screen and (max-width: 1000px)
				width: vwm(383px) !important
				margin-left: vwm(-3px) !important
				margin-top: vwm(73px) !important
	.page 
		&--telnov
			background-color: #D3E3EC
			transition: all 300ms
			min-height: 100vh
			&.blue
				background-color: #D4E5EF
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1093px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/bg-8.svg)
				background-repeat: no-repeat
				background-position: 91% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
	.main__block
		min-height: vw(1063px)
	.collage-mob--2
		@media screen and (max-width: 1000px)
			width: vwm(378px)
			height: vwm(237px)
			background-image: url(../assets/img/collage-2-full.png)
			background-size: contain
			margin-top: vwm(107px)
			background-repeat: no-repeat
	.main__block--taste
		@media screen and (max-width: 1000px)
			min-height: 140vw !important
		@media screen and (max-width: 1000px)
			.intro__round--2
				top: -16.90278vw
	.content__image--d1
		width: vw(484px)
	.plane_1-wrap
		position: relative
		z-index: 10
	.plane_1
		display: block
		position: absolute
		width: vw(288px)
		height: vw(276px)
		background-image: url(../assets/img/dubas/photo-1.png)
		background-size: contain
		top: vw(-119px)
		left: vw(-47px)
		transform: rotate(-10deg)
	.content--d1
		.content__info
			width: 42vw
			.content__text
				font-family: 'Futura PT'
			.content__large
				width: 90%
	.content--d2
		.content__info
			width: 38vw
			margin-top: vw(-50px)
			.content__text
				font-family: 'Futura PT'
			.content__large
				width: 90%
	.content--d3
		.content__image
			width: 36vw
		.content__info
			width: 42vw
			margin-top: vw(-120px)
			.content__text
				font-family: 'Futura PT'
			.content__large
				width: 90%
	.content__image--d2
		width: 43.51111vw !important
		margin-right: 1vw
		margin-left: -4vw
	.content__el-d2
		width: vw(325px)
		height: vw(237px)
		background-image: url(../assets/img/dubas/photo-2.png)
		background-size: contain
		top: vw(-119px)
		right: vw(-47px)
		position: absolute
		background-repeat: no-repeat
	.el_mor
		width: vw(303px)
		height: vw(162px)
		background-image: url(../assets/img/dubas/photo-3.png)
		background-size: contain
		top: vw(-49px)
		left: vw(-27px)
		position: absolute
		background-repeat: no-repeat
	@media screen and (max-width: 1000px)
		.intro--9 .intro__text
			width: 90%
		.intro--9 .intro__round--9
			top: 0
			&:after
				display: none
		.content--d1 .content__info
			width: 100%
		.content--d1 .content__info .content__large
			width: 100%
		.content__image--d1
			width: 100%
		.plane_1
			width: 30vw
			height: 30vw
			background-repeat: no-repeat
		.content--d2
			padding-top: 0
		.content--d2 .content__info
			width: 100%
		.content__image--d2
			width: 100% !important
		.content__el-d2
			width: 33vw
			height: 33vw
			background-repeat: no-repeat
		.content__image--d2
			margin: auto
		.content--d3 .content__title
			font-size: 8vw
		.content--d3 .content__info
			width: 100%
		.content--d3 .content__info .content__large
			width: 100%
		.content--d3 .content__image
			width: 100%
		.el_mor
			width: 40vw
			height: 40vw
			background-repeat: no-repeat
		.content--d1
			padding-top: vw(20px)
			padding-bottom: vw(20px)
		.content--d2
			padding-bottom: vw(20px)
		.content--d3
			padding-top: vw(20px)
			padding-bottom: vw(20px)
</style>