<template>
	<div class="burger" data-scroll-lock-scrollable>
		<div class="burger__logo"><a href="javascript:void(0)" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5886042447&iu=/81006599/hmiru-mc/count')"></a></div>
		<div class="burger__close" @click="$emit('closeBurger')">
			<span></span>
			<span></span>
		</div>
		<div class="burger__menu">
			<div class="burger__menu-item"><router-link to="/taste" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_taste'})">вкус</router-link></div>
			<div class="burger__menu-item"><router-link to="/inspiration" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_inspiration'})">вдохновение</router-link></div>
			<div class="burger__menu-item"><router-link to="/spirit" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_spirit'})">дух</router-link></div>
			<div class="burger__menu-item"><router-link to="/motion" @click.native="$gtag.event('click', {event_category: 'link','event_label': 'link_motion'})">движение</router-link></div>
			<div class="burger__menu-item">
				<a href="javascript:void(0)" @click="showFriends = !showFriends" :class="{active: showFriends}">друзья австрии</a>
				<div class="burger__menu-child" v-if="showFriends">
					<router-link to="/dubas">Алекс Дубас</router-link>
					<router-link to="/raevsky">Владимир Раевский</router-link>
					<router-link to="/yakovina">Ольга Яковина</router-link>
					<router-link to="/rastegaeva">Ольга Растегаева</router-link>
					<router-link to="/telnov">Дмитрий Тельнов</router-link>
				</div>
			</div>
		</div>
		<FooterBlock classListFooter="footer--burger"></FooterBlock>
	</div>
</template>

<script>
	import FooterBlock from '@/components/FooterBlock'
	export default {
		data() {
			return {
				showFriends: false,
			}
		},
		methods: {
			openLink(link) {
				window.open(link, '_blank').focus();
				this.$gtag.event('click', {event_category: 'link','event_label': 'link_austria'})
			}
		},
		components: {
			FooterBlock,
		},
	}
</script>

<style lang="sass">
	.footer--burger
		margin-top: vwm(150px) !important
		padding-bottom: vwm(30px)
	.burger
		width: 100vw
		height: 100vh
		position: fixed
		top: 0
		left: 0
		overflow-y: scroll
		z-index: 999999999
		background-image: url(../assets/img/burger-bg.jpg)
		background-size: 100vw
		background-color: #D9CFC1
		&__menu
			margin-top: vwm(111px)
			&-item
				font-size: vwm(30px)
				font-family: 'PosteRetro'
				color: #000
				text-decoration: none
				text-align: center
				margin-bottom: vwm(35px)
				a
					color: #000
					text-decoration: none
					letter-spacing: 0.8vw
					&.active
						color: #C20E1A
			&-child
				padding-top: vwm(23px)
				a
					color: #000
					letter-spacing: 0.3vw
					font-size: vwm(24px)
					font-family: 'Futura PT'
					display: block
					margin-bottom: vwm(22px)
		&__close
			width: vwm(35px)
			height: vwm(30px)
			position: absolute
			top: vwm(17px)
			right: vwm(20px)
			background-image: url(../assets/img/close.svg)
			background-repeat: no-repeat
			background-size: contain
		&__logo
			display: block
			width: vw(108px)
			height: vw(20px)
			background-image: url(../assets/img/logo.png)
			background-size: contain
			margin-left: vw(-11px)
			position: absolute
			width: vwm(152px)
			height: vwm(29px)
			left: vwm(23px)
			top: vwm(23px)
			background-repeat: no-repeat
			a
				display: block
				width: 100%
				height: 100%

</style>