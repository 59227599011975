import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import YandexShare from '@cookieseater/vue-yandex-share';
import VueGtag from "vue-gtag"

Vue.component('yandex-share', YandexShare);

Vue.use(VueGtag, {
   config: { id: "UA-216728324-1" }
 /*config: { id: "UA-000000000-1" }*/
});


Vue.config.productionTip = false
new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
