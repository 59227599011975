<template>
	<div class="page page--inspiration" :class="{white: white, blue: blue}">
		<Burger @closeBurger="showBurger = false" v-if="showBurger" />
		<transition name="slide-fader">
			<div class="header__nav header__nav--inner" v-if="showBack">
				<HeaderBlock :secondMenu="true" :active="4" />
			</div>
		</transition>
		<transition name="slide-fader">
			<div class="main__header-panel" v-if="showPanel">
				<div class="main__header-mc"><a href="https://www.marieclaire.ru/" target="_blank"></a></div>
				<div class="main__header-burger" @click="showBurger = true">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</transition>
		<div class="container main__block">
			<div class="page__header" data-aos="fade-up">
				<HeaderBlock />
			</div>
			<Intro>
				<template v-slot:title>
					вдохновение
				</template>
				<template v-slot:text>
					Мы приглашаем вас на бокал молодого вина в венский хойригер, в гости<br/>к Джеймсу Бонду в Долину Эцталь, в пещеру под ледником в Циллертале,<br/>в мастерскую к Густаву Климту, на один из самых старинных рождественских базаров Европы и в покои к самой красивой принцессе 16 века.
				</template>
				<template v-slot:collage>
					<div class="collage collage--1">
						<img src="../assets/img/collage-1.png" alt="">
						<div class="collage__el-1" data-aos="fade-up" data-aos-delay="1300" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el-2" data-aos="fade-up" data-aos-delay="1400" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el-3" data-aos="fade-up" data-aos-delay="1500" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el-4" data-aos="fade-up" data-aos-delay="1600" data-aos-offset="-1000"><span></span></div>
						<div class="collage__el-5" data-aos="fade-up" data-aos-delay="1800" data-aos-offset="-1000"><span></span></div>
					</div>
					<!--<div class="collage-mob collage-mob--1 collage--mob"></div>-->
					<div class="intro__round"></div>
				</template>
			</Intro>
		</div>
		<div class="bg-white">
			<Content>
				<template v-slot:title>
					розы климта
				</template>
				<template v-slot:location>
					<a href="https://g.page/KlimtVilla?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_13'})">Feldmühlgasse 11, 1130 Wien, Австрия</a>
				</template>
				<template v-slot:text>
					На полотне Густава Климта «Фруктовый сад с розами» изображен утопающий в зелени цветущий сад, усеянный яркими цветами. Эта картина была написана в 1912 году - к тому времени основоположник модерна в австрийской живописи уже переехал со своими любимыми кошками в одноэтажный дом на Фельдмюльгассе 11 в Хицинге (13 район на юго-западе Вены). В этой небольшой вилле в стиле необарокко, доставшейся благодаря меценату, Климт устроил себе удобную мастерскую. Огромные окна выходили в сад, который стал для художника источником вдохновения. Климт высадил там клумбы с&nbsp;розами и сам за ними ухаживал вплоть до своей смерти в 1918 году. Именно на этой вилле, начиная с переезда сюда в 1911 году, Климт создал многие шедевры, включая женские портреты «Адель Блох-Бауэр II», «Портрет Фридерики Марии Беер», «Невеста», «Адам и Ева», “Цветущий сад”.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--h1 content__image--desk"><img src="../assets/img/i1.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__rose" data-aos="zoom-in" data-aos-delay="200"></div><div class="content__round content__round--1"></div></div>
					<div class="content__image content__image--h1 content__image--mob"><img src="../assets/img/i1-2.jpg" alt=""><div class="content__rose" data-aos="zoom-in" data-aos-delay="200"></div><div class="content__round content__round--1"></div></div>
				</template>
			</Content>
			<Content :short="true" :reverse="true">
				<template v-slot:text>
					После смерти художника дом перестроили, через какое-то время он&nbsp;достался нацистам, но спустя несколько десятилетий его выкупило государство и там размещались школа и склад. Потом здание, в том числе мастерскую, реконструировали. С 2014 года здесь работает частный музей. Сейчас в семи комнатах все выглядит так же, как и&nbsp;при жизни Климта: и деревянный табурет, на котором художник восседал за мольбертом, и кушетка, на которой у окна позировали натурщицы. 
				</template>
				<template v-slot:large>
					Сад, а также любимые цветы Климта - нежные дамасские розы, запечатленные на многих картинах, - тоже удалось спасти: их заново высадил садовый эксперт из Вены.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--2 content__image--desk"><img src="../assets/img/i2.jpg" class="rellax" data-rellax-speed="0.4" data-rellax-percentage="0.5" alt=""></div>
					<div class="content__image content__image--2 content__image--mob"><img src="../assets/img/i2-2.jpg" alt=""></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="true" infoclass="content__info--3" largeclass="content__large--3">
				<template v-slot:title>
					по следам <br/>бонда
				</template>
				<template v-slot:location>
					<a href="https://g.page/007Elements?share" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_14'})">Dorfstraße 115, 6450 Sölden, Австрия</a>
				</template>
				<template v-slot:text>
					В 24-м фильме о Джеймсе Бонде “Спектр” в футуристическом кубе со стеклянными стенами расположена клиника, где скрывается давний противник Бонда - герой Кристофа Вальца. Эта клиника, а точнее - ресторан, находятся на вершине горы Гаислахкогель в Зельдене, на высоте 3048 метров. Через стеклянный фасад площадью 900 кв. м Альпы видны как на ладони. Именно здесь, на “вершине мира”, сняли множество эпизодов “Спектра”. Узнать подробности съемок и погрузиться в атмосферу фильма можно в расположенном рядом музее под названием 007 Elements. Музей, кстати, тоже футуристический, под стать последним фильмам о Бонде. Залы с интерактивными видео-инсталляциями переносят в ультра-динамичную реальность блокбастера Сэма Мендеса и других фильмов “Бондианы”. 
				</template>
				<template v-slot:large>
					А переварить впечатления, подкрепиться<br/>и заодно насладиться панорамой долины Эцталь, можно в ICE Q - местном ресторане высокой кухни. </template>
				<template v-slot:image>
					<div class="content__image content__image--3"><img src="../assets/img/i3.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__round content__round--2"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--4" largeclass="content__large--4" :reverse="true">
				<template v-slot:title>
					как у христа <br/>за пазухой
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/Ag99Yw2JB98e3yhG8" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_15'})">5020 Salzburg, Австрия</a>
				</template>
				<template v-slot:text>
					Рождественский базар Младенца Христа в Старом Городе Зальцбурга существует с незапамятных времен. В конце 15 века на местной Соборной площади во время Адвента торговали сладостями, куклами и прочими товарами. В 17 веке Зальцбургский рождественский базар стали называть „Рынком Св.Николая“. Он считался одним из главных праздничных базаров Европы наряду с рынками в Вене, Париже, Амстердаме и Нюрнберге. В наши дни Зальцбургский Базар открыт с конца ноября по 1 января. В 100 киосках можно купить подарок на любой вкус: гирлянды ручной работы, игрушки, утварь, бижутерию, поделки от местных мастеров. Голодными с Зальцбургского базара уйти сложно: к пуншу или глинтвейну стоит заказать марципан и прочие сладости, сосиски и другие местные специалитеты. 
				</template>
				<template v-slot:large>
					Пища для души тоже найдется: рождественские чтения для детей, мини-представления, концерты хоровой музыки и многое другое. </template>
				<template v-slot:image>
					<div class="content__image content__image--4 content__image--h5"><img src="../assets/img/i4.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__deamon" data-aos="fade-right" data-aos-delay="200"></div><div class="content__round content__round--4"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="true" infoclass="content__info--5" largeclass="content__large--5">
				<template v-slot:title>
					ледовый дворец
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/dQxd8HHigGFhKrXM7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_16'})">Hintertux 794, 6293 Tux, Австрия</a>
				</template>
				<template v-slot:text>
					Ледник Хинтерукс знаменит не только своими прекрасно подготовленными спусками, но и прячущейся внутри гигантской ледяной пещере. Nature’s Ice Palace - “Ледовый дворец” 15 лет назад случайно обнаружил местный житель и знаток гор по имени Роман Эрлер. Сейчас попасть в Ледовый дворец можно круглый год. Вход в пещеру находится в нескольких минутах от верхней станции подъемника Gefrorene Wand (3250 м). Главное - позаботиться о теплой одежде и обуви: температура внутри не поднимается выше нуля. Посетителей ждут длинные ледяные коридоры высотой более 15 метров, переливающиеся разными цветами кристаллы льда, замерзшие водопады, колодец глубиной 30 метров и озеро, по которому можно прокатиться на надувной лодке. Происхождение и состав пещеры изучают ученые со всего мира, но пока ее появление остается загадкой. 
				</template>
				<template v-slot:large>
					Согласно гипотезам, этим причудливым ледяным коридорам может быть несколько тысяч лет. </template>
				<template v-slot:image>
					<div class="content__image content__image--5"><img src="../assets/img/i5.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__ice" data-aos="fade-left" data-aos-delay="200"></div><div class="content__round content__round--2"></div></div>
				</template>
			</Content>
			<Quote class="quote quote--o">
				<template v-slot:portrait>
					<div class="quote__main-portrait quote__main-portrait--o" data-aos="fade-up" data-aos-delay="10"><img src="../assets/img/rastegaeva.png" alt=""></div>
				</template>
				<template v-slot:element-1>
					<div class="quote__main-element-1 quote__main-element-1--o" data-aos="fade-up" data-aos-delay="30"></div>
				</template>
				<template v-slot:element-2>
					<div class="quote__main-element-2 quote__main-element-2--o" data-aos="fade-up" data-aos-delay="50"></div>
				</template>
				<template v-slot:title>
					<div class="quote__main-title quote__main-title--o" data-aos="fade-up" data-aos-delay="70">ольга растегаева</div>
				</template>
				<template v-slot:text>
					<div class="quote__info-text quote__info-text--o" data-aos="fade-up" data-aos-delay="10">Свой секрет ледник открыл только Роману Эрлеру, местному жителю и гиду, который в 2007 году заинтересовался открывшейся широкой трещиной. Мне повезло познакомиться с первооткрывателем лично и вместе с ним исследовать систему ледяных пещер изнутри...</div>
				</template>
				<template v-slot:button>
					<div class="quote__info-button" data-aos="fade-up" data-aos-delay="20"><router-link to="/rastegaeva">Читать далее</router-link></div>
				</template>
			</Quote>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--6" largeclass="content__large--6" :reverse="true">
				<template v-slot:title>
					карнавал <br/>плюс
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/dk3nZYMegchkamXG7" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_17'})">Gemeinde Imst, Австрия</a>
				</template>
				<template v-slot:text>
					В феврале зима постепенно сдает свои позиции, природа пробуждается - в Тироле этот естественный цикл отмечают шумным и красочным карнавалом, чьи корни уходят в далёкое прошлое. Самое колоритное празднество проходит в старинном альпийском местечке Имст. В 2012 году Имстский Шеменлауфен - традиционную карнавальную процессию с участием более 900 человек! - включили в список Всемирного культурного наследия ЮНЕСКО. Этот карнавал устраивается раз в четыре года, а готовиться к нему начинают за долгие месяцы. За два года до этого события в Имсте происходит детский карнавал Буабенфаснахт, в котором участвуют около 400 ребят в возрасте от 6 и до 16 лет. Одноногие шуты с тяжелыми бубенцами в масках ручной работы, ведьмы в невероятных нарядах, злые и добрые духи, медведи, разукрашенные повозки - на несколько дней тихий Имст превращается в ослепительное и неподражаемое королевство барокко и рококо.
				</template>
				<template v-slot:image>
					<div class="content__image content__image--6 content__image--h6"><img src="../assets/img/i6.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__carnaval" data-aos="fade-up" data-aos-delay="200"></div><div class="content__round content__round--4"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-blue">
			<Content :smalltitle="true" infoclass="content__info--7" largeclass="content__large--7">
				<template v-slot:title>
					открытка <br/>из китцбюэля
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/sQAqpXwnnDTDtBs96" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_18'})">Kitzbühel 6370, Австрия</a>
				</template>
				<template v-slot:text>
					На курорте Китцбюэль вас ждут не только 233 км трассы и горнолыжный сезон длиной 7 месяцев. На этом старинном тирольском курорте можно также познакомиться с творчеством австрийского художника и архитектора Альфонса Вальде (1891–1958). Уроженец Вены большую часть жизни провел в Китцбюэле, где сейчас работает посвященный ему музей. Главными сюжетами его картин были залитые солнцем заснеженные вершины тирольских Альп, одинокие горные хижины, скользящие по склонам лыжники и местные жители в традиционных нарядах. Эти картины завоевали множество наград и постепенно стали “брендом Китцбюэля”. В 1923 году Вальде запустил издательство открыток и до 1950 года продал более миллиона открыток и 200 000 цветных изображений своих картин. 
				</template>
				<template v-slot:large>
					Их визитной карточкой был ослепительный белый снег на фоне яркого синего неба - в солнечные зимние дни<br/>в Тироле до сих пор говорят: «Сегодня небо Вальде и снег Вальде!» </template>
				<template v-slot:image>
					<div class="content__image content__image--6"><img src="../assets/img/i7.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__picture" data-aos="fade-up"></div><div class="content__round content__round--2"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<Content :smalltitle="true" infoclass="content__info--8" largeclass="content__large--8" :reverse="true">
				<template v-slot:title>
					первый музей <br/>в мире
				</template>
				<template v-slot:location>
					<a href="https://goo.gl/maps/g17rp1EUEyjHfYb27" target="_blank" @click="$gtag.event('click', {event_category: 'link','event_label': 'link_location_19'})">Schloßstraße 20, 6020 Innsbruck, Австрия</a>
				</template>
				<template v-slot:text>
					С Замком Амбрас, одной из главных достопримечательностей Инсбрука, связана одна из ярчайших любовных историй Австрии 16 века. Эрцгерцог Фердинанд II, вместо предназначенной для него принцессы, женился на дочери простого купца Филиппине Вельзер и тайно поселил ее в своем огромном замке. Его избранница была не только очень хороша собой, но и обладала глубокими познаниями в разных областях. Например, она разбиралась в лечебных травах, написала поваренную книгу, а также устроила в замке “СПА эпохи Ренессанса” - роскошную купальню с двумя бассейнами. Сам эрцгерцог собрал у себя огромную коллекцию портретов, оружия и драгоценностей, а также разбил вокруг замка парк площадью 20 гектаров с искусственным водопадом, прудами, множеством беседок и разнообразной флорой и фауной. Сейчас в Замке Амбрас, в придачу к средневековым скульптурам, доспехам и прочим раритетам из коллекции Фердинанда, можно увидеть живопись эпохи Габсбургов, включая работы Веласкеса, Тициана, Ван Дейка и Рубенса.
				</template>
				<template v-slot:large>
					В Испанском зале проходят концерты классической музыки, а любимый парк Филиппины Вейльзер и вовсе сохранился в первозданном виде. </template>
				<template v-slot:image>
					<div class="content__image content__image--8 content__image--h7"><img src="../assets/img/i8.jpg" class="rellax" data-rellax-speed="-0.4" data-rellax-percentage="0.5" alt=""><div class="content__man" data-aos="fade-right" data-aos-delay="200"></div><div class="content__round content__round--5"></div></div>
				</template>
			</Content>
		</div>
		<div class="bg-white">
			<div class="container">
				<FooterBlock />
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderBlock from '@/components/HeaderBlock'
	import Content from '@/components/Content'
	import Quote from '@/components/Quote'
	import Burger from '@/components/Burger'
	import Intro from '@/components/Intro'
	import FooterBlock from '@/components/FooterBlock'
	import scrollLock from 'scroll-lock'
	import Rellax from 'rellax';
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	export default {
		name: 'Page',
		data() {
			return {
				showBack: false,
				showBurger: false,
				showPanel: false,
				white: false,
				blue: false,
				scroll25: false,
				scroll50: false,
				scroll75: false,
				scroll100: false,
			}
		},
		components: {
			HeaderBlock,
			Intro,
			Burger,
			Content,
			FooterBlock,
			Quote,
		},
		mounted() {
			this.$gtag.pageview({page_path: '/inspiration'})
			if (window.innerWidth > 1000){
				new Rellax('.rellax');
			}
			scrollLock.enablePageScroll();
			AOS.init({
				duration: 600
			});
			window.addEventListener('scroll', this.handleScroll);
		},
		watch: {
			showBurger(val) {
				console.log(val)
				if (val == true) {
					scrollLock.disablePageScroll();
				} else {
					scrollLock.enablePageScroll();
				}
			}
		},
		methods: {
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				console.log(procentScroll)
				if (window.innerWidth > 1000) {
					if (procentScroll < 10) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 10) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 28) {
						this.white = false;
						this.blue = true;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 38) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 50) {
						this.white = false;
						this.blue = true;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 64) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 73) {
						this.white = false;
						this.blue = true;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 83) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 90) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_100'})
							this.scroll100 = true;
						}
					}
				} else {
					if (procentScroll < 5) {
						this.white = false;
						this.blue = false;
					}
					if (procentScroll > 5) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 23) {
						this.white = false;
						this.blue = true;
						if (!this.scroll25) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_25'})
							this.scroll25 = true;
						}
					}
					if (procentScroll > 34) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 45) {
						this.white = false;
						this.blue = true;
						if (!this.scroll50) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_50'})
							this.scroll50 = true;
						}
					}
					if (procentScroll > 63) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 74) {
						this.white = false;
						this.blue = true;
						if (!this.scroll75) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_75'})
							this.scroll75 = true;
						}
					}
					if (procentScroll > 85) {
						this.white = true;
						this.blue = false;
					}
					if (procentScroll > 90) {
						if (!this.scroll100) {
							this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_inspiration_100'})
							this.scroll100 = true;
						}
					}
				}
				if (procentScroll > 3) {
					this.showBack = true;
				} else {
					this.showBack = false;
				}
				if (procentScroll > 5) {
					this.showPanel = true;
				} else {
					this.showPanel = false;
				}
			},
		}
	}
</script>

<style lang="sass">
	.quote--o
		&:after
			@media screen and (max-width: 1000px)
				top: vwm(74px)
		@media screen and (max-width: 1000px)
			padding-top: vwm(100px)
		.quote__info-text:before
			left: vw(-43px)
			top: vw(-17px)
			@media screen and (max-width: 1000px)
				left: vwm(-43px)
				top: vwm(-18px)
	.quote__main-title--o
		margin-top: vw(197px)
		margin-left: vw(58px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(331px)
			margin-left: vwm(61px)
			margin-bottom: vwm(71px)
	.quote__main-element-1--o
		background-image: url(../assets/img/quote-el-1-o.png)
		width: vw(232px)
		height: vw(304px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(49px)
		top: vw(-140px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(232px)
			height: vwm(304px)
			top: vwm(-15px)
			left: vwm(55px)
	.quote__main-element-2--o
		background-image: url(../assets/img/quote-el-2-o.png)
		width: vw(216px)
		height: vw(128px)
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		left: vw(-53px)
		top: vw(-79px)
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(216px)
			height: vwm(128px)
			top: vwm(42px)
			left: vwm(-46px)
	.quote__info-text--o
		width: vw(711px)
		font-size: vw(22px)
		line-height: vw(29px)
		@media screen and (max-width: 1000px)
			margin-left: vwm(63px)
	.quote__main-portrait--o
		width: vw(178px)
		height: vw(151px)
		position: absolute
		z-index: 20
		left: vw(174px)
		top: vw(31px)
		@media screen and (max-width: 1000px)
			width: vwm(178px)
			height: vwm(151px)
			top: vwm(153px)
			left: vwm(182px)
	.page__header
		@media screen and (max-width: 1000px)
			margin-top: vwm(-22px)
	.bg-white
		//background-color: #fff
	.bg-blue
		//background-color: #D4E5EF
		//background-image: url(../assets/img/noise.png)
		position: relative
		//&:after
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
		//	position: absolute
		//	bottom: 0
		//&:before
		//	content: ''
		//	display: block
		//	width: 100%
		//	height: vw(100px)
		//	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
		//	position: absolute
		//	top: 0
	.collage
		&--1
			margin-top: vw(-60px)
			margin-left: vw(-69px)
			width: vw(1350px)
			@media screen and (max-width: 1000px)
				width: vwm(428px)
				margin-top: vwm(68px)
				margin-left: vwm(-41px)
		img
			width: 100%
			z-index: 3
			position: relative
		&__el
			&-1
				position: absolute
				right: vw(-41px)
				top: vw(19px)
				z-index: 4
				@media screen and (max-width: 1000px)
					right: vwm(-6px)
					top: vwm(10px)
				span
					width: vw(231px)
					height: vw(307px)
					background-image: url(../assets/img/collage-el-1.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 4s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(74px)
						height: vwm(98px)
			&-2
				position: absolute
				left: vw(-68px)
				top: vw(350px)
				z-index: 4
				@media screen and (max-width: 1000px)
					top: vwm(124px)
					left: vwm(-8px)
				span
					width: vw(404px)
					height: vw(347px)
					background-image: url(../assets/img/collage-el-2.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: customZoom
					animation-duration: 3s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(129px)
						height: vwm(111px)
			&-3
				position: absolute
				left: vw(-47px)
				top: 0
				z-index: 2
				@media screen and (max-width: 1000px)
					left: vwm(-2px)
				span
					width: vw(196px)
					height: vw(312px)
					background-image: url(../assets/img/collage-el-3.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 2s
					animation-iteration-count: infinite
					animation-timing-function: linear
					animation-direction: reverse
					@media screen and (max-width: 1000px)
						width: vwm(63px)
						height: vwm(100px)
			&-4
				position: absolute
				left: vw(-15px)
				top: vw(94px)
				z-index: 1
				@media screen and (max-width: 1000px)
					left: vwm(-3px)
					top: vwm(31px)
				span
					width: vw(474px)
					height: vw(385px)
					background-image: url(../assets/img/collage-el-4.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: upDown
					animation-duration: 3s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(152px)
						height: vwm(123px)
			&-5
				position: absolute
				left: vw(868px)
				top: vw(67px)
				z-index: 5
				@media screen and (max-width: 1000px)
					left: vwm(249px)
					top: vwm(23px)
				span
					width: vw(105px)
					height: vw(275px)
					background-image: url(../assets/img/collage-el-5.png)
					background-size: contain
					background-repeat: no-repeat
					display: block
					animation: simpleZoom
					animation-duration: 3s
					animation-iteration-count: infinite
					animation-timing-function: linear
					@media screen and (max-width: 1000px)
						width: vwm(34px)
						height: vwm(88px)
	.page
		&--inspiration
			background-color: #D9BFA1
			min-height: 100vh
			transition: all 300ms
			&.blue
				background-color: #D4E5EF
			&.white
				background-color: #fff
			&:after
				content: ''
				display: block
				background-image: url(../assets/img/noise.png)
				background-size: vw(1440px)
				width: 100%
				height: vw(1101px)
				position: absolute
				top: 0
				background-position: 50% 0
				@media screen and (max-width: 1000px)
					height: 160.77778vw
			&:before
				content: ''
				display: block
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: url(../assets/img/inspiration.svg)
				background-repeat: no-repeat
				background-position: 50% vw(193px)
				background-size: vw(1410px)
				@media screen and (max-width: 1000px)
					background-size: vwm(757px)
					background-position: 65% vwm(160px)
	.main__block
		min-height: vw(1063px)
		@media screen and (max-width: 1000px)
			min-height: 200vw !important
	.collage--desk
		@media screen and (max-width: 1000px)
			display: none
	.collage--mob
		display: none
		@media screen and (max-width: 1000px)
			display: block
	.collage-mob--1
		width: vwm(378px)
		height: vwm(237px)
		background-image: url(../assets/img/collage-1-mobile.png)
		background-size: contain
		margin-top: vwm(67px)
		background-repeat: no-repeat
</style>