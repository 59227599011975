<template>
  <div id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
</style>

<style lang="sass">
  #app
    position: relative
    overflow: hidden
    min-height: 100vh
    display: block
    font-family: 'Futura PT', sans-serif
    background-color: #D9CFC1
    background-image: url(./assets/img/bg.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    @media screen and (max-width: 1000px)
      background-image: url(./assets/img/bg-m.svg)
      background-attachment: fixed
      background-size: vwm(817px)
      background-position: 70% 58vw
  *
    box-sizing: border-box
  body
    font-family: 'Tautz', sans-serif
    overflow-x: hidden
    position: relative
  .container
    width: vw(1240px)
    margin: auto
    position: relative
    @media screen and (max-width: 1000px)
      width: 100%
  @keyframes rotateRound
    from
      transform: rotate(0deg) scale(0.99)
    to
      transform: rotate(360deg) scale(0.989)
  @keyframes customZoom
    0%
      transform: rotate(0deg) scale(1)
    50%
      transform: rotate(10deg) scale(1.08)
    100%
      transform: rotate(0deg) scale(1)
  @keyframes simpleZoom
    0%
      transform: scale(1)
    50%
      transform: scale(1.04)
    100%
      transform: scale(1)
  @keyframes upDown
    0%
      transform: translateY(0px)
    50%
      transform: translateY(-20px)
    100%
      transform: translateY(0px)
  @keyframes leftRight
    0%
      transform: translateX(0px)
    50%
      transform: translateX(-20px)
    100%
      transform: translateX(0px)
  @keyframes upDownAlt
    0%
      transform: translateY(0px) rotate(0deg)
    50%
      transform: translateY(-20px) rotate(10deg)
    100%
      transform: translateY(0px) rotate(0deg)
  .serpator
    height: 15px
</style>

<style>
@font-face {
  font-family: 'PosteRetro';
  src: url('./assets/fonts/posteretro.eot');
  src: url('./assets/fonts/posteretro.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/posteretro.woff2') format('woff2'),
    url('./assets/fonts/posteretro.woff') format('woff'),
    url('./assets/fonts/posteretro.ttf') format('truetype'),
    url('./assets/fonts/posteretro.svg#posteretro') format('svg');
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('./assets/fonts/futurabkbtrusbyme_book-webfont.woff2') format('woff2'),
         url('./assets/fonts/futurabkbtrusbyme_book-webfont.woff') format('woff'),
         url('./assets/fonts/futurabkbtrusbyme_book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'Futura Md BT';
    src: url('./assets/fonts/futuramdbtrusbyme_medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/futuramdbtrusbyme_medium-webfont.woff') format('woff'),
         url('./assets/fonts/futuramdbtrusbyme_medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Gogol';
    src: url('./assets/fonts/subset-Gogol.woff2') format('woff2'),
        url('./assets/fonts/subset-Gogol.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('./assets/fonts/subset-FuturaPT-Book.woff2') format('woff2'),
        url('./assets/fonts/subset-FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.rellax {
    transition: transform .6s;
}
.page__header {
  position: relative;
  z-index: 100;
}
.main__header-logo {
  z-index: 200;
}
</style>